import './records.css';
import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

const Records = ({language}) => {

    const [currentType, setCurrentType] = useState("pl");
    const [currentDivision, setCurrentDivision] = useState("cl");
    const [currentSex, setCurrentSex] = useState("m");
    const [records, setRecords] = useState([]);

    const [labels] = useState({
        "records": {"french": "RECORDS PROVINCIAUX", "english": "PROVINCIAL RECORDS"},
        "powerlifting": {"french": "Dynamophilie", "english": "Powerlifting"},
        "classic": {"french": "Classique", "english": "Classic"},
        "equipped": {"french": "Équipé", "english": "Equipped"},
        "men": {"french": "Hommes", "english": "Men"},
        "women": {"french": "Femmes", "english": "Women"},
        "weight-class": {"french": "Classe de poids", "english": "Weight Class"},
        "age-class": {"french": "Classe d'âge", "english": "Age Class"},
        "name": {"french": "Nom", "english": "Name"},
        "lift": {"french": "Lever", "english": "Lift"},
        "weight": {"french": "Poids", "english": "Weight"},
        "certificate": {"french": "Certificat", "english": "Certificate"},
        "download": {"french": "Télécharger", "english": "Download"},
        "search": {"french": "Rechercher", "english": "Search"},
        "o": "Open",
        "j": "Junior",
        "sj": "Sub-Junior",
        "m1": "Master I",
        "m2": "Master II",
        "m3": "Master III",
        "m4": "Master IV",
        "cl": "Classique",
        "eq": "Équipé"
    });

    const [meets, setMeets] = useState([]);

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                setMeets(myJson);
            });
    // eslint-disable-next-line
    }, []);

    const powerlifting = useRef();
    const bench = useRef();

    const classic = useRef();
    const equipped = useRef();

    const men = useRef();
    const women = useRef();

    const onPowerlifting = () => {
        setCurrentType("pl");
        powerlifting.current.className = "selected";
        bench.current.className = "";

    }

    const onBench = () => {
        setCurrentType("bp");
        powerlifting.current.className = "";
        bench.current.className = "selected";
    }

    const onClassic = () => {
        setCurrentDivision("cl");
        classic.current.className = "selected";
        equipped.current.className = "";

    }

    const onEquipped = () => {
        setCurrentDivision("eq");
        classic.current.className = "";
        equipped.current.className = "selected";
    }

    const onMen = () => {
        setCurrentSex("m");
        men.current.className = "selected";
        women.current.className = "";
    }

    const onWomen = () => {
        setCurrentSex("w");
        men.current.className = "";
        women.current.className = "selected";
    }

    const onClick = () => {
        let wc = "";
        if (currentSex === "m") {
            wc = document.getElementById("m-weight-class").value;
        } else if (currentSex === "w") {
            wc = document.getElementById("w-weight-class").value;
        }
        
        let url = 'https://sheltered-inlet-15640.herokuapp.com/api/records?' + 
        'type=' + currentType + 
        '&division=' + currentDivision + 
        '&gender=' + currentSex + 
        '&ac=' + document.getElementById("age").value +
        '&wc=' + wc;

        fetch(url, {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              setRecords(myJson);
          }).catch(function (e) {

        });
    }

    return (
        <div className="records-container">
            <h1>{labels['records'][language["language"]]}</h1>

            <div className="toggle-groups">
                <div className="toggle-group">
                    <button ref={powerlifting} className="selected" onClick={onPowerlifting}>{labels['powerlifting'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={bench} onClick={onBench}>Bench Press</button>
                </div>
                <div className="toggle-group">
                    <button ref={classic} className="selected" onClick={onClassic}>{labels['classic'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={equipped} onClick={onEquipped}>{labels['equipped'][language["language"]]}</button>
                </div>
                <div className="toggle-group">
                    <button ref={men} className="selected" onClick={onMen}>{labels['men'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={women} onClick={onWomen}>{labels['women'][language["language"]]}</button>
                </div>
                <div className="toggle-group-filter">
                    <select name="age" id="age" className="">
                        <option value="o">Open</option>
                        <option value="sj">Sub-Junior</option>
                        <option value="j">Junior</option>
                        <option value="m1">Master I</option>
                        <option value="m2">Master II</option>
                        <option value="m3">Master III</option>
                        <option value="m4">Master IV</option>
                    </select>

                    {
                        (currentSex === "m") &&
                            <select name="m-weight-class" id="m-weight-class" className="filter">
                                <option value="%2B120kg">+120kg</option>
                                <option value="-120kg">-120kg</option>
                                <option value="-105kg">-105kg</option>
                                <option value="-93kg">-93kg</option>
                                <option value="-83kg">-83kg</option>
                                <option value="-74kg">-74kg</option>
                                <option value="-66kg">-66kg</option>
                                <option value="-59kg">-59kg</option>
                                <option value="-53kg">-53kg</option>
                            </select>
                    }

                    {
                        (currentSex === "w") &&
                            <select name="w-weight-class" id="w-weight-class" className="filter">
                                <option value="%2B84kg">+84kg</option>
                                <option value="-84kg">-84kg</option>
                                <option value="-76kg">-76kg</option>
                                <option value="-69kg">-69kg</option>
                                <option value="-63kg">-63kg</option>
                                <option value="-57kg">-57kg</option>
                                <option value="-52kg">-52kg</option>
                                <option value="-47kg">-47kg</option>
                                <option value="-43kg">-43kg</option>
                            </select>
                    }
                </div>

                <div className="search">
                    <button onClick={onClick}>{labels['search'][language["language"]]}</button>
                </div>
            </div>

            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <th>{labels['name'][language["language"]]}</th>
                                <th>{labels['lift'][language["language"]]}</th>
                                <th>{labels['weight'][language["language"]]}</th>
                                <th>{labels['certificate'][language["language"]]}</th>
                            </tr>

                            {
                                records.map((record, indexi) => {
                                    return(
                                        <tr key={indexi}>
                                            <td>{
                                            new Date(meets.find((e) => e.meetId === record.meetId).date).getUTCDate().toString().padStart(2, '0') + '-' +
                                                        (new Date(meets.find((e) => e.meetId === record.meetId).date).getUTCMonth()+1).toString().padStart(2, '0') + '-' +
                                                        new Date(meets.find((e) => e.meetId === record.meetId).date).getUTCFullYear()}</td>
                                            <td key={indexi+1}>{record.name}</td>
                                            <td key={indexi+2}>{record.lift}</td>
                                            <td key={indexi+3}>{record.weight}</td>
                                            <td key={indexi+4}>
                                            <Link target="_blank" rel="noopener noreferrer" key={indexi+5} to={
                                                        '/certificate/' + record.name +
                                                        '/' + record.weight +
                                                        '/' + record.lift +
                                                        '/' + labels[record.division] +
                                                        '/' + labels[record.age] +
                                                        '/' + record.wc +
                                                        '/' + meets.find((e) => e.meetId === record.meetId).nom +
                                                        '/' + 
                                                        new Date(meets.find((e) => e.meetId === record.meetId).date).getUTCDate() + '-' +
                                                        (new Date(meets.find((e) => e.meetId === record.meetId).date).getUTCMonth()+1) + '-' +
                                                        new Date(meets.find((e) => e.meetId === record.meetId).date).getUTCFullYear()
                                                    }> {labels['download'][language["language"]]}
                                            </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Records
