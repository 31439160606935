import './header.css';
import logo from './assets/all-white.png'
import Dropdown from './Dropdown'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

const Header = ({language, setLanguage}) => {

    useEffect(() => {
        let x = getCookie("language");
        if(x === "") {
            setCookie("language", "french", 3000);
        } else {
            setLanguage({"language": x});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const [labels] = useState({
        "donation": {"french": "FAIRE UN DON", "english": "MAKE A DONATION"},
        "newsletter": {"french": "INFOLETTRE", "english": "NEWSLETTER"},
        "donation-link": {"french": "https://www.jedonneenligne.org/sportsquebec/PLSPLDYN/", "english": "https://www.imakeanonlinedonation.org/sportsquebec/PLSPLDYN/"},
        "membership": {"french": "DEVENIR MEMBRE", "english": "BECOME A MEMBER"},
        "switch-language": {"french": "ENGLISH", "english": "FRANÇAIS"}
    });

    const changeLanguage = () => {
        if(language.language === "french") {
            setLanguage({"language": "english"});
            setCookie("language", "english", 3000)
        } else {
            setLanguage({"language": "french"});
            setCookie("language", "french", 3000)
        }
    };

    return (
        <header>
            <div className="header-container">
                <div className="dropdown-container"><Dropdown language={language} /></div>
                <Link to="/"><img src={logo} alt="Logo" className="header-logo" /></Link>
                <ul className="buttons-container">
                    <li><Link to={"/membership"} className="membership">{labels['membership'][language["language"]]}</Link></li>
                    <li><a target="_blank" rel="noopener noreferrer" href={labels['donation-link'][language["language"]]}>{labels['donation'][language["language"]]}</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href={"https://mailchi.mp/93edcf7368bc/infolettre-fqd"}>{labels['newsletter'][language["language"]]}</a></li>
                    <li><button onClick={changeLanguage}>{labels['switch-language'][language["language"]]}</button></li>
                </ul>
                <Link to="/hamburger" className="hamburger">&#9776;</Link>
            </div>
        </header>
    );
}

export default Header
