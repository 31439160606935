import './standards.css';
import {useState, useRef} from 'react'

const Standards = ({language}) => {

    const [labels] = useState({
        "standards": {"french": "NORMES DE QUALIFICATION", "english": "QUALIFICATION STANDARDS"},
        "powerlifting": {"french": "Dynamophilie", "english": "Powerlifting"},
        "classic": {"french": "Classique", "english": "Classic"},
        "equipped": {"french": "Équipé", "english": "Equipped"},
        "men": {"french": "Hommes", "english": "Men"},
        "women": {"french": "Femmes", "english": "Women"},
        "weight-class": {"french": "Classe de poids", "english": "Weight Class"}
    })

    const [currentTable] = useState({"dcph": [["-53 kg", "", 295, 382.5, "", "", "", ""], ["-59 kg", 457.5, 327.5, 427.5, 347.5, 317.5, 282.5, 255], ["-66 kg", 507.5, 362.5, 475, 382.5, 350, 312.5, 280], ["-74 kg", 565, 395, 527.5, 417.5, 382.5, 340, 305], ["-83 kg", 610, 427.5, 577.5, 450, 412.5, 367.5, 330], ["-93 kg", 637.5, 452.5, 610, 477.5, 437.5, 390, 350], ["-105 kg", 667.5, 477.5, 630, 502.5, 460, 410, 367.5], ["-120 kg", 690, 497.5, 645, 522.5, 480, 427.5, 382.5], ["120+ kg", 705, 505, 660, 535, 490, 432.5, 390]],
                                                        "dcpf": [["-43 kg", "", 147.5, 215, "", "", "", ""], ["-47 kg", 260, 157.5, 230, 182.5, 152.5, 137.5, 127.5], ["-52 kg", 280, 170, 250, 195, 165, 147.5, 137.5], ["-57 kg", 310, 185, 272.5, 210, 177.5, 160, 147.5], ["-63 kg", 337.5, 197.5, 292.5, 227.5, 190, 172.5, 157.5], ["-69 kg", 352.5, 210, 320, 242.5, 205, 185, 170], ["-76 kg", 372.5, 225, 337.5, 260 , 217.5, 197.5, 180], ["-84 kg", 395, 240, 350, 275, 230 , 207.5, 190], ["84+ kg", 412.5, 252.5, 370, 290, 242.5, 220, 200]],
                                                        "dcnh": [["-53 kg", "", 312.5, 405, "", "", "", ""], ["-59 kg", 482.5, 347.5, 450, 375, 347.5, 317.5, 282.5], ["-66 kg", 530, 385, 497.5, 415, 382.5, 350, 312.5], ["-74 kg", 580, 420, 542.5, 452.5, 417.5, 382.5, 340], ["-83 kg", 625, 452.5, 585, 487.5, 450, 412.5, 367.5], ["-93 kg", 665, 480, 620, 517.5, 477.5, 437.5, 390], ["-105 kg", 697.5, 505, 652.5, 545, 502.5, 460, 410], ["-120 kg", 727.5, 527.5, 677.5, 565, 522, 480, 427.5], ["120+ kg", 745, 537.5, 695, 580, 535, 490, 432.5]],
                                                        "deph": [["-53 kg", "", 327.5, 387.5, "", "", "", ""], ["-59kg", 460, 365, 430, 375, 347.5, 317.5, 282.5], ["-66 kg", 507.5, 402.5, 475, 415, 382.5, 350, 312.5], ["-74kg", 552.5, 437.5, 520, 452.5, 417.5, 382.5, 340], ["-83 kg", 597.5, 472.5, 560, 487.5, 450, 412.5, 367.5], ["-93 kg", 635, 502.5, 595, 517.5, 477.5, 437.5, 390], ["-105 kg", 665, 527.5, 625, 545, 502.5, 460, 410], ["-120 kg", 695, 550, 647.5, 565, 522.5, 480, 427.5], ["120+ kg", 710, 562.5, 665, 580, 535, 490, 432.5]],
                                                        "dcnf": [["-43 kg", "", 155, 232.5, "", "", "", ""], ["-47 kg", 275, 167.5, 250, 210, 182.5, 152.5, 137.5], ["-52 kg", 297.5, 180, 272.5, 227.5, 195, 165, 147.5], ["-57 kg", 320, 195, 292.5, 245, 210, 177.5, 160], ["-63 kg", 347.5, 207.5, 317.5, 265, 277.5, 190, 172.5], ["-69 kg", 370, 222.5, 337.5, 282.5, 242.5, 205, 185], ["-76 kg", 392.5, 237.5, 360, 300, 257.5, 217.5, 197.5], ["-84 kg", 417.5, 252.5, 382.5, 320, 275, 230, 207.5], ["84+ kg", 432.5, 265, 405, 337.5, 290, 242.5, 220]],
                                                        "depf": [["-43 kg", "", 162.5, 222.5, "", "", "", ""], ["-47 kg", 250, 175, 240, 210, 182.5, 152.5, 137.5], ["-52 kg", 272.5, 187.5, 260, 227.5, 195, 165, 147.5], ["-57 kg", 292.5, 202.5, 280, 245, 210, 177.5, 160], ["-63 kg", 317.5, 217.5, 302.5, 265, 227.5, 190, 172.5], ["-69 kg", 337.5, 232.5, 322.5, 282.5, 242.5, 205, 185], ["-76 kg", 360, 247.5, 345, 300, 257.5, 217.5, 195], ["-84 kg", 382.5, 262.5, 367.5, 320, 275, 230, 207.5], ["84+ kg", 395, 277.5, 387.5, 337.5, 290, 242.5, 220]],
                                                        "denh": [["-53 kg", "", 357.5, 452.5, "", "", "", ""], ["-59 kg", 507.5, 397.5, 505, 440, 375, 347.5, 317.5], ["-66 kg", 560, 437.5, 557.5, 485, 415, 382.5, 350], ["-74 kg", 612.5, 480, 605, 527.5, 452.5, 417.5, 382.5], ["-83 kg", 660, 517.5, 655, 570, 487.5, 450, 412.5], ["-93 kg", 702.5, 547.5, 695, 605, 517.5, 477.5, 437.5], ["-105 kg", 737.5, 577.5, 730, 635, 545, 502.5, 460], ["-120 kg", 765, 485, 760, 662.5, 565, 522.5, 480], ["120+ kg", 787.5, 615, 777.5, 677.5, 580, 535, 490]],
                                                        "denf": [["-43 kg", "", 195, 260, "", "", "", ""], ["-47 kg", 282.5, 207.5, 275, 240, 210, 182.5, 152.5], ["-52 kg", 305, 222.5, 297.5, 260, 227.5, 195, 165], ["-57 kg", 330, 240, 320, 280, 245, 210, 177.5], ["-63 kg", 355, 260, 347.5, 302.5, 265, 227.5, 190], ["-69 kg", 380, 277.5, 370, 322.5, 282.5, 242.5, 205], ["-76 kg", 405, 295, 392.5, 342.5, 300, 257.5, 217.5], ["-84 kg", 430, 315, 417.5, 365, 320, 275, 230], ["84+ kg", 462.5, 332.5, 432.5, 377.5, 337.5, 290, 242.5]],

                                                        "bcph": [["-53 kg", "", 62.5, 75, "", "", "", ""], ["-59 kg", 92.5, 70, 90, 75, 65, 60, 55], ["-66 kg", 105, 75, 100, 82.5, 72.5, 65, 60], ["-74 kg", 115, 80, 107.5, 90, 77.5, 70, 65], ["-83 kg", 127.5, 90, 120, 100, 87.5, 77.5, 70], ["-93 kg", 132.5, 95, 125, 105, 90, 82.5, 75], ["-105 kg", 140, 100, 132.5, 110, 95, 87.5, 80], ["-120 kg", 145, 102.5, 137.5, 115, 100, 90, 82.5], ["120+ kg", 152.5, 110, 145, 120, 105, 95, 87.5]],
                                                        "bcpf": [["-43 kg", "", 30, 35, "", "", "", ""], ["-47 kg", 45, 30, 40, 32.5, 27.5, 25, 25], ["-52 kg", 45, 32.5, 42.5, 35, 30, 27.5, 27.5], ["-57 kg", 52.5, 35, 45 ,37.5, 32.5, 30, 30], ["-63 kg", 57.5, 37.5, 50, 42.5, 35, 32.5, 32.5], ["-69 kg", 60, 40, 52.5, 45, 37.5, 35, 35], ["-76 kg", 65, 42.5, 55, 47.5, 40, 37.5, 37.5], ["-84 kg", 70, 42.5, 60, 50, 42.5, 37.5, 37.5], ["84+ kg", 72.5, 45, 62.5, 52.5, 45, 40, 40]],
                                                        "bcnh": [["-53 kg", "", 62.5, 87.5, "", "", "", ""], ["-59 kg", 107.5, 75, 102.5, 85, 75, 65, 60], ["-66 kg", 117.5, 82.5, 115, 95, 82.5, 72.5, 65], ["-74 kg", 132.5, 90, 125, 105, 90, 77.5, 70], ["-83 kg", 142.5, 100, 137.5, 115, 100, 87.5, 77.5], ["-93 kg", 152.5, 102.5, 145, 120, 105, 90, 82.5], ["-105 kg", 160, 110, 152.5, 127.5, 110, 95, 87.5], ["-120 kg", 165, 115, 160, 132.5, 115, 100, 90], ["120+ kg", 172.5, 120, 165, 137.5, 120, 105, 95]],
                                                        "beph": [["-53 kg", "", 62.5, 87.5, "", "", "", ""], ["-59 kg", 105, 72.5, 102.5, 85, 75, 65, 60], ["-66 kg", 117.5, 82.5, 115, 95, 82.5, 72.5, 65], ["-74 kg", 130, 87.5, 125, 105, 90, 77.5, 70], ["-83 kg", 142.5, 100, 137.5, 115, 100, 87.5, 77.5], ["-93 kg", 150, 102.5, 145, 120, 105, 90, 82.5], ["-105 kg", 157.5, 107.5, 152.5, 127.5, 110, 95, 87.5], ["-120 kg", 165, 115, 160, 132.5, 115, 100, 90], ["120+ kg", 172.5, 120, 165, 137.5, 120, 105, 95]],
                                                        "bcnf": [["-43 kg", "", 30, 45, "", "", "", ""], ["-47 kg", 50, 32.5, 47.5, 40, 32.5, 27.5, 25], ["-52 kg", 55, 35, 50, 42.5, 35, 30, 27.5], ["-57 kg", 60, 37.5, 55, 45, 37.5, 32.5, 30], ["-63 kg", 65, 40, 60, 50, 42.5, 35, 32.5], ["-69 kg", 67.5, 42.5, 62.5, 52.5, 45, 37.5, 35], ["-76 kg", 72.5, 45, 67.5, 55, 47.5, 40, 37.5], ["-84 kg", 77.5, 50, 72.5, 60, 50, 42.5, 40], ["84+ kg", 80, 52.5, 75, 62.5, 52.5, 45, 40]],
                                                        "bepf": [["-43 kg", "", 30, 45, "", "", "", ""], ["-47 kg", 47.5, 30, 47.5, 40, 32.5, 27.5, 25], ["-52 kg", 52.5, 32.5, 50, 42.5, 35, 30, 27.5], ["-57 kg", 60, 35, 52.5, 45, 37.5, 32.5, 30], ["-63 kg", 65, 40, 60, 50, 42.5, 35, 32.5], ["-69 kg", 67.5, 42.5, 62.5, 52.5, 45, 37.5, 35], ["-76 kg", 70, 45, 67.5, 55, 47.5, 40, 37.5], ["-84 kg", 77.5, 47.5, 70, 60, 50, 42.5, 37.5], ["84+ kg", 80, 50, 75, 62.5, 52.5, 45, 40]],
                                                        "benh": [["-53 kg", "", 70, 97.5, "", "", "", ""], ["-59 kg", 122.5, 85, 115, 97.5, 85, 75, 65], ["-66 kg", 137.5, 92.5, 127.5, 107.5, 95, 82.5, 72.5], ["-74 kg", 150, 102.5, 142.5, 120, 105, 90, 77.5], ["-83 kg", 165, 115, 155, 130, 115, 100, 87.5], ["-93 kg", 172.5, 120, 165, 137.5, 120, 105, 90], ["-105 kg", 182.5, 125, 172.5, 145, 127.5, 110, 95], ["-120 kg", 187.5, 130, 180, 150, 132.5, 115, 100], ["120+ kg", 197.5, 137.5, 187.5, 157.5, 137.5, 120, 105]],
                                                        "benf": [["-43 kg", "", 32.5, 47.5, "", "", "", ""], ["-47 kg", 57.5, 35, 47.5, 42.5, 40, 32.5, 27.5], ["-52 kg", 62.5, 40, 50, 47.5, 40, 35, 30], ["-57 kg", 67.5, 42.5, 55, 52.5, 45, 37.5, 32.5], ["-63 kg", 72.5, 47.5, 62.5, 57.5, 50, 42.5, 35], ["-69 kg", 77.5, 50, 65, 60, 52.5, 45, 37.5], ["-76 kg", 82.5, 52.5, 67.5, 62.5, 55, 47.5, 40], ["-84 kg", 87.5, 57.5, 70, 67.5, 60, 50, 42.5], ["84+ kg", 92.5, 60, 82.5, 70, 62.5, 52.5, 45]]
                                                    })
                                                    
    const [currentTableKey, setCurrentTableKey] = useState("dcph")

    const powerlifting = useRef();
    const bench = useRef();

    const classic = useRef();
    const equipped = useRef();

    const provincial = useRef();
    const national = useRef();

    const men = useRef();
    const women = useRef();

    const onPowerlifting = () => {
        setCurrentTableKey(currentTableKey.substr(0, 0) + "d" + currentTableKey.substr(1, 3));
        powerlifting.current.className = "selected";
        bench.current.className = "";
    }

    const onBench = () => {
        setCurrentTableKey(currentTableKey.substr(0, 0) + "b" + currentTableKey.substr(1, 3));
        powerlifting.current.className = "";
        bench.current.className = "selected";
    }

    const onClassic = () => {
        setCurrentTableKey(currentTableKey.substr(0, 1) + "c" + currentTableKey.substr(2, 3));
        classic.current.className = "selected";
        equipped.current.className = "";
    }

    const onEquipped = () => {
        setCurrentTableKey(currentTableKey.substr(0, 1) + "e" + currentTableKey.substr(2, 3));
        classic.current.className = "";
        equipped.current.className = "selected";
    }

    const onProvincial = () => {
        setCurrentTableKey(currentTableKey.substr(0, 2) + "p" + currentTableKey.substr(3, 3));
        provincial.current.className = "selected";
        national.current.className = "";
    }

    const onNational = () => {
        setCurrentTableKey(currentTableKey.substr(0, 2) + "n" + currentTableKey.substr(3, 3));
        provincial.current.className = "";
        national.current.className = "selected";
    }

    const onMen = () => {
        setCurrentTableKey(currentTableKey.substr(0, 3) + "h");
        men.current.className = "selected";
        women.current.className = "";
    }

    const onWomen = () => {
        setCurrentTableKey(currentTableKey.substr(0, 3) + "f");
        men.current.className = "";
        women.current.className = "selected";
    }

    return (
        <div className="standards-container">
            <h1>{labels['standards'][language["language"]]}</h1>

            <div className="toggle-groups">
                <div className="toggle-group">
                    <button ref={powerlifting} className="selected" onClick={onPowerlifting}>{labels['powerlifting'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={bench} onClick={onBench}>Bench Press</button>
                </div>
                <div className="toggle-group">
                    <button ref={classic} className="selected" onClick={onClassic}>{labels['classic'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={equipped} onClick={onEquipped}>{labels['equipped'][language["language"]]}</button>
                </div>
                <div className="toggle-group">
                    <button ref={provincial} className="selected" onClick={onProvincial}>Provincial</button>
                    <div className="line" />
                    <button ref={national} onClick={onNational}>National</button>
                </div>
                <div className="toggle-group">
                    <button ref={men} className="selected" onClick={onMen}>{labels['men'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={women} onClick={onWomen}>{labels['women'][language["language"]]}</button>
                </div>
            </div>

            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th>{labels['weight-class'][language["language"]]}</th>
                                <th>Open</th>
                                <th>Sub-Junior</th>
                                <th>Junior</th>
                                <th>Master 1</th>
                                <th>Master 2</th>
                                <th>Master 3</th>
                                <th>Master 4</th>
                            </tr>

                            {
                                currentTable[currentTableKey].map((row, indexi) => {
                                    return(
                                        <tr key={indexi}>
                                            {
                                                row.map((value, indexj) => {
                                                    return(
                                                        <td key={indexj}>{value}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Standards;
