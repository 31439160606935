import './standards.css';
import {useState, useEffect, useRef} from 'react'

const Standards = ({language}) => {

    const initializeObject = () => {
        const keys = [
            'dcph', 'dcpf', 'dcnh', 'deph', 'dcnf', 'depf', 'denh', 'denf',
            'bcph', 'bcpf', 'bcnh', 'beph', 'bcnf', 'bepf', 'benh', 'benf'
        ];
        const obj = {};
        keys.forEach(key => {
            obj[key] = Array.from({ length: 9 }, () => Array(8).fill(""));
        });
        return obj;
    };

    const buildKey = (item) => {
        let key = '';
        key += item.type === 'pl' ? 'd' : 'b';
        key += item.division === 'cl' ? 'c' : 'e';
        key += item.level === 'nats' ? 'n' : 'p';
        key += item.gender === 'm' ? 'h' : 'f';
        return key;
    };

    const getRowIndex = (gender, wc) => {
        const maleWeightClasses = ["-53 kg", "-59 kg", "-66 kg", "-74 kg", "-83 kg", "-93 kg", "-105 kg", "-120 kg", "120+ kg"];
        const femaleWeightClasses = ["-43 kg", "-47 kg", "-52 kg", "-57 kg", "-63 kg", "-69 kg", "-76 kg", "-84 kg", "84+ kg"];
        if (gender === 'm') {
            return maleWeightClasses.indexOf(wc);
        } else if (gender === 'w') {
            return femaleWeightClasses.indexOf(wc);
        }
        return -1; // Retourne -1 si la classe de poids n'est pas trouvée
    };

    const getColumnIndex = (ac) => {
        const ageCategories = ["Open", "Sub-Junior", "Junior", "Master 1", "Master 2", "Master 3", "Master 4"];
        return ageCategories.indexOf(ac) + 1; // Ajoute 1 pour correspondre aux colonnes spécifiées
    };

    const processData = (data) => {
        const obj = initializeObject();
        data.forEach(item => {
            const key = buildKey(item);
            if (obj[key]) {
                const rowIndex = getRowIndex(item.gender, item.wc);
                const colIndex = getColumnIndex(item.ac);
                if (rowIndex !== -1 && colIndex !== -1) {
                    obj[key][rowIndex][colIndex] = item.total;
                }
            }
        });
        return obj;
    };

    const [labels] = useState({
        "standards": {"french": "NORMES DE QUALIFICATION", "english": "QUALIFICATION STANDARDS"},
        "powerlifting": {"french": "Dynamophilie", "english": "Powerlifting"},
        "classic": {"french": "Classique", "english": "Classic"},
        "equipped": {"french": "Équipé", "english": "Equipped"},
        "men": {"french": "Hommes", "english": "Men"},
        "women": {"french": "Femmes", "english": "Women"},
        "weight-class": {"french": "Classe de poids", "english": "Weight Class"}
    })

    const [currentTableKey, setCurrentTableKey] = useState("dcph");
    const [processedData, setProcessedData] = useState({});

    // Fonction pour appeler l'API et obtenir le JSON
    const fetchStandards = async () => {
        try {
            const response = await fetch('https://sheltered-inlet-15640.herokuapp.com/api/v1/standards', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const processedData = processData(data);
            setProcessedData(processedData);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    // Utiliser useEffect pour appeler fetchStandards lors du montage du composant
    useEffect(() => {
        fetchStandards();
    }, []);

    const maleWeightClasses = ["-53 kg", "-59 kg", "-66 kg", "-74 kg", "-83 kg", "-93 kg", "-105 kg", "-120 kg", "+120 kg"];
    const femaleWeightClasses = ["-43 kg", "-47 kg", "-52 kg", "-57 kg", "-63 kg", "-69 kg", "-76 kg", "-84 kg", "+84 kg"];

    const powerlifting = useRef();
    const bench = useRef();

    const classic = useRef();
    const equipped = useRef();

    const provincial = useRef();
    const national = useRef();

    const men = useRef();
    const women = useRef();

    const onPowerlifting = () => {
        setCurrentTableKey(currentTableKey.substr(0, 0) + "d" + currentTableKey.substr(1, 3));
        powerlifting.current.className = "selected";
        bench.current.className = "";
    }

    const onBench = () => {
        setCurrentTableKey(currentTableKey.substr(0, 0) + "b" + currentTableKey.substr(1, 3));
        powerlifting.current.className = "";
        bench.current.className = "selected";
    }

    const onClassic = () => {
        setCurrentTableKey(currentTableKey.substr(0, 1) + "c" + currentTableKey.substr(2, 3));
        classic.current.className = "selected";
        equipped.current.className = "";
    }

    const onEquipped = () => {
        setCurrentTableKey(currentTableKey.substr(0, 1) + "e" + currentTableKey.substr(2, 3));
        classic.current.className = "";
        equipped.current.className = "selected";
    }

    const onProvincial = () => {
        setCurrentTableKey(currentTableKey.substr(0, 2) + "p" + currentTableKey.substr(3, 3));
        provincial.current.className = "selected";
        national.current.className = "";
    }

    const onNational = () => {
        setCurrentTableKey(currentTableKey.substr(0, 2) + "n" + currentTableKey.substr(3, 3));
        provincial.current.className = "";
        national.current.className = "selected";
    }

    const onMen = () => {
        setCurrentTableKey(currentTableKey.substr(0, 3) + "h");
        men.current.className = "selected";
        women.current.className = "";
    }

    const onWomen = () => {
        setCurrentTableKey(currentTableKey.substr(0, 3) + "f");
        men.current.className = "";
        women.current.className = "selected";
    }

    return (
        <div className="standards-container">
            <h1>{labels['standards'][language["language"]]}</h1>

            <div className="toggle-groups">
                <div className="toggle-group">
                    <button ref={powerlifting} className="selected" onClick={onPowerlifting}>{labels['powerlifting'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={bench} onClick={onBench}>Bench Press</button>
                </div>
                <div className="toggle-group">
                    <button ref={classic} className="selected" onClick={onClassic}>{labels['classic'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={equipped} onClick={onEquipped}>{labels['equipped'][language["language"]]}</button>
                </div>
                <div className="toggle-group">
                    <button ref={provincial} className="selected" onClick={onProvincial}>Provincial</button>
                    <div className="line" />
                    <button ref={national} onClick={onNational}>National</button>
                </div>
                <div className="toggle-group">
                    <button ref={men} className="selected" onClick={onMen}>{labels['men'][language["language"]]}</button>
                    <div className="line" />
                    <button ref={women} onClick={onWomen}>{labels['women'][language["language"]]}</button>
                </div>
            </div>

            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th>{labels['weight-class'][language["language"]]}</th>
                                <th>Open</th>
                                <th>Sub-Junior</th>
                                <th>Junior</th>
                                <th>Master 1</th>
                                <th>Master 2</th>
                                <th>Master 3</th>
                                <th>Master 4</th>
                            </tr>

                            {
                                processedData[currentTableKey] && processedData[currentTableKey].map((row, indexi) => {
                                    const weightClass = currentTableKey.endsWith('h') ? maleWeightClasses[indexi] : femaleWeightClasses[indexi];
                                    return (
                                        <tr key={indexi}>
                                            <td>{weightClass}</td>
                                            {
                                                row.slice(1).map((value, indexj) => {
                                                    return (
                                                        <td key={indexj}>{value}</td>
                                                    );
                                                })
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Standards;
