import './referees.css';

import {useState} from 'react'

import amelie from '../assets/organization/ame.jpg'
import ameliekennes from '../assets/referees/ameliekennes.jpg'
import annabelle from '../assets/referees/annabelle.jpg'
import aurelie from '../assets/referees/aurelienguyen.jpg'
import cathd from '../assets/referees/catherinedavid.jpg'
import ce from '../assets/referees/ce.jpg'
import chloe from '../assets/organization/chloe.jpg'
import dan from '../assets/referees/dan.jpg'
import gab from '../assets/referees/gab.jpg'
import guillaume from '../assets/referees/guillaume.jpg'
import fr from '../assets/referees/fr.jpg'
import jg from '../assets/referees/jg.jpg'
import mathieu from '../assets/referees/mathieu.jpg'
import nick from '../assets/referees/nick.jpg'
import jay from '../assets/referees/jay.jpg'
import justine from '../assets/referees/justine.jpg'
import jsr from '../assets/referees/jsr.jpg'
import kat from '../assets/referees/kat.jpg'
import louis from '../assets/referees/louis.jpg'
import mathis from '../assets/referees/mathis.jpg'
import maxime from '../assets/referees/maxime.jpg'
import mymy from '../assets/referees/mymy.jpg'
import pm from '../assets/referees/pascalemercier.jpg'
import remi from '../assets/referees/remi.jpg'
import sandra from '../assets/referees/sandra.jpg'
import wt from '../assets/referees/williamtalbot.jpg'

const Referees = ({language}) => {

    const [labels] = useState({
        "referees": {"french": "ARBITRES", "english": "REFEREES"},
        "training": {"french": "En Formation", "english": "Training"}
    })

    return (
        <div className="referees-container">
            <h1>{labels['referees'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="referees-group-container">
                        <h3>International (IPF)</h3>
                        <div className="referees">
                            <div className="referee">
                            <h3>Louis Lévesque</h3>
                            <img src={louis} alt=""/>
                            <p>International I (IPF)</p><br/>
                            <p>Sherbrooke</p>
                            </div>
                            <div className="referee">
                                <h3>Jean-Sébastien Rhéaume</h3>
                                <img src={jsr} alt=""/>
                                <p>International I (IPF)</p><br/>
                                <p>Saint-Benoît-Labre</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="referees-group-container">
                        <h3>National (CPU)</h3>
                        <div className="referees">
                            <div className="referee">
                                <h3>François Cazes</h3>
                                <img src={fr} alt=""/>
                                <p>Pintendre</p>
                            </div>
                            <div className="referee">
                                <h3>Katherine Duckworth</h3>
                                <img src={kat} alt=""/>
                                <p>Montréal</p>
                            </div>
                            <div className="referee">
                                <h3>Nicolas Déry</h3>
                                <img src={nick} alt=""/>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Gabriel Aubé</h3>
                                <img src={gab} alt=""/>
                                <p>Montérégie</p>
                            </div>
                            <div className="referee">
                                <h3>Annabelle Rouleau</h3>
                                <img src={annabelle} alt=""/>
                                <p>Montérégie</p>
                            </div>
                            <div className="referee">
                                <h3>Mathis Jolicoeur</h3>
                                <img src={mathis} alt=""/>
                                <p>Montréal</p>
                            </div>
                            <div className="referee">
                                <h3>Myrlande Kane</h3>
                                <img src={mymy} alt=""/>
                                <p>Sherbrooke</p>
                            </div>
                            <div className="referee">
                                <h3>Justine Mageau</h3>
                                <img src={justine} alt=""/>
                                <p>Montréal</p>
                            </div>
                            <div className="referee">
                                <h3>Chloé Leblanc</h3>
                                <img src={chloe} alt=""/>
                                <p>Châteauguay</p>
                            </div>
                            <div className="referee">
                                <h3>Aurélie Nguyen</h3>
                                <img src={aurelie} alt=""/>
                                <p>Montréal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="referees-group-container">
                        <h3>Provincial  (FQD)</h3>
                        <div className="referees">
                            <div className="referee">
                                <h3>Daniel Royer</h3>
                                <img src={dan} alt=""/>
                                <p>Ste-Marguerite</p>
                            </div>
                            <div className="referee">
                                <h3>Rémi Fortier</h3>
                                <img src={remi} alt=""/>
                                <p>Scott Jonction</p>
                            </div>
                            <div className="referee">
                                <h3>Guillaume Arseneau</h3>
                                <img src={guillaume} alt=""/>
                                <p>Lévis</p>
                            </div>
                            <div className="referee">
                                <h3>Amélie Picher-Plante</h3>
                                <img src={amelie} alt=""/>
                                <p>Montérégie</p>
                            </div>
                            <div className="referee">
                                <h3>Mathieu Kennedy</h3>
                                <img src={mathieu} alt=""/>
                                <p>Montérégie</p>
                            </div>
                            <div className="referee">
                                <h3>Jonathan Gilbert</h3>
                                <img src={jg} alt=""/>
                                <p>Saguenay</p>
                            </div>
                            <div className="referee">
                                <h3>Charles-Éric Vézina</h3>
                                <img src={ce} alt=""/>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Pascale Mercier</h3>
                                <img src={pm} alt=""/>
                                <p>Saguenay</p>
                            </div>
                            <div className="referee">
                                <h3>Maxime Savard</h3>
                                <img src={maxime} alt=""/>
                                <p>Saguenay</p>
                            </div>
                            <div className="referee">
                                <h3>Amélie Kennes</h3>
                                <img src={ameliekennes} alt=""/>
                                <p>Laval</p>
                            </div>
                            <div className="referee">
                                <h3>Jean-Michel Hovington</h3>
                                <p>Saguenay</p>
                            </div>
                            <div className="referee">
                                <h3>William Talbot</h3>
                                <img src={wt} alt=""/>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Catherine David</h3>
                                <img src={cathd} alt=""/>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Judy Ann Ruelland</h3>
                                <p>St-Jean-sur-Richelieu</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="referees-group-container">
                        <h3>{labels['training'][language["language"]]}</h3>
                        <div className="referees">
                            <div className="referee">
                                <h3>Jérémie Borgia</h3>
                                <img src={jay} alt=""/>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Sandra Gagnon</h3>
                                <img src={sandra} alt=""/>
                                <p>Montérégie</p>
                            </div>
                            <div className="referee">
                                <h3>Xin Di Dong</h3>
                                <p>Montréal</p>
                            </div>
                            <div className="referee">
                                <h3>Christopher Pivin</h3>
                                <p>Sherbrooke</p>
                            </div>
                            <div className="referee">
                                <h3>Maxandre Dusablon</h3>
                                <p>Sherbrooke</p>
                            </div>
                            <div className="referee">
                                <h3>Gabriel Gaudet</h3>
                                <p>Acton Vale</p>
                            </div>
                            <div className="referee">
                                <h3>Charles-Étienne Dame</h3>
                                <p>Saint-Hyacinthe</p>
                            </div>
                            <div className="referee">
                                <h3>Anthony Fréchette</h3>
                                <p>Saint-Jérôme</p>
                            </div>
                            <div className="referee">
                                <h3>Sandrine Tremblay</h3>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Emmanuelle Tremblay</h3>
                                <p>Québec</p>
                            </div>
                            <div className="referee">
                                <h3>Catherine Tremblay</h3>
                                <p>Montréal</p>
                            </div>
                            <div className="referee">
                                <h3>Amélie Bouchard</h3>
                                <p>Beaupré</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Referees
