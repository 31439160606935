import './results.css';
import {useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

import TableEntry from './TableEntry'

const Results = ({language}) => {

    const [labels] = useState({
        "results": {"french": "RÉSULTATS", "english": "RESULTS"},
        "by-athletes": {"french": "Par athlètes", "english": "By Athletes"},
        "by-competitions": {"french": "Par compétitions", "english": "By Competitions"},
        "by-results": {"french": "Par résultats", "english": "By Results"},
        "search": {"french": "Rechercher", "english": "Search"},
        "sex": {"french": "Sexe", "english": "Sex"},
        "Homme": {"french": "Hommes", "english": "Men"},
        "Femme": {"french": "Femmes", "english": "Women"},

        "m": {"french": "Hommes", "english": "Men"},
        "f": {"french": "Femmes", "english": "Women"},
        "w": {"french": "Femmes", "english": "Women"},

        "name": {"french": "Nom", "english": "Name"},
        "age": {"french": "Classe d'âge", "english": "Age Class"},
        "weight": {"french": "Poids Corporel", "english": "Weight"},
        "weight-class": {"french": "Classe de poids", "english": "Weight Class"},
        "competition": {"french": "Compétition", "english": "Competition"},

        "Classique": {"french": "C", "english": "C"},
        "cl": {"french": "C", "english": "C"},
        "Équipé": {"french": "E", "english": "E"},
        "eq": {"french": "E", "english": "E"},
        "Dynamophilie": {"french": "D", "english": "P"},
        "pl": {"french": "D", "english": "P"},
        "Bench Press": {"french": "B", "english": "B"},
        "bp": {"french": "B", "english": "B"},

        "o": {"french": "Open", "english": "Open"},
        "sj": {"french": "Sub-Junior", "english": "Sub-Junior"},
        "j": {"french": "Junior", "english": "Junior"},
        "m1": {"french": "Master I", "english": "Master I"},
        "m2": {"french": "Master II", "english": "Master II"},
        "m3": {"french": "Master III", "english": "Master III"},
        "m4": {"french": "Master IV", "english": "Master IV"}
    })

    const [currentResults, setCurrentResults] = useState([]);
    const [competitions, setCompetitions] = useState({2024: {"": []}});
    const [yearSelected, setYearSelected] = useState(2024);

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/', {
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }).then(function(response){
            return response.json();
          }).then(function(myJson) {
              myJson.sort((a,b) => (a.date > b.date) ? -1 : ((b.date > a.date) ? 1 : 0))
              let meetsObjects = {};
              // eslint-disable-next-line
              myJson.map((meet) => {
                let date = new Date(meet.date);
                if(date < new Date()) {
                    if(!(date.getFullYear() in meetsObjects)) {
                        meetsObjects[date.getFullYear()] = {};
                    }
                    meetsObjects[date.getFullYear()][meet.nom] = meet.meetId;
                }
            });
            setCompetitions(meetsObjects);
          });
    }, []);
    
    const onYearSelected = () => {
        setYearSelected(document.getElementById("yearId").value);
    }

    const onClick = () => {
        let year = document.getElementById("yearId").value;
        let competitionsInTheYear = competitions[year];
        let name = document.getElementById("competition-name").value;
        let id = competitionsInTheYear[name];

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + id + '/results', {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          }).then(function(response){
              return response.json();
            }).then(function(myJson) {
                setCurrentResults(myJson);
            });
    };

    const addName = (result) => {
        if(result.isNovice) {
            return result.name + " (novice)";
        } else {
            return result.name;
        }
    };

    return (
        <div className="meets-results-container">
            <h1>{labels['results'][language["language"]]}</h1>

            <div className="results-toggle-group">
                <div className="button-container">
                    <button className="selected">{labels['by-competitions'][language["language"]]}</button>
                    <div className="line" />
                </div>
            </div>

            <div className="results-filter-container">
                <div className="select-container">
                    <select name="year" id="yearId" onChange={onYearSelected}>
                        {
                            Object.keys(competitions).sort(
                                function(a, b) {
                                return b - a;
                            }).map((year, index) => {
                                return(<option key={index} value={year}>{year}</option>)
                            })
                        }
                    </select>
                    <select name="competition-name" id="competition-name">
                        {
                            Object.keys(competitions[yearSelected]).map((competition, index) => {
                                return(<option key={index} value={competition}>{competition}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="search-container">
                    <button onClick={onClick}>{labels['search'][language["language"]]}</button>
                </div>
            </div>
             
                <h4>{labels['results'][language["language"]]}</h4>

                <div className="table-container">
                    <div className="table-wrapper">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{labels['sex'][language["language"]]}</th>
                                    <th>{labels['name'][language["language"]]}</th>
                                    <th>Type</th>
                                    <th>{labels['weight'][language["language"]]}</th>
                                    <th>{labels['weight-class'][language["language"]]}</th>
                                    <th>s1</th>
                                    <th>s2</th>
                                    <th>s3</th>
                                    <th>b1</th>
                                    <th>b2</th>
                                    <th>b3</th>
                                    <th>d1</th>
                                    <th>d2</th>
                                    <th>d3</th>
                                    <th>Total</th>
                                    <th>GL</th>
                                </tr>

                                {
                                    currentResults.map((result, indexi) => {
                                        return(
                                            <tr key={indexi+18}>
                                                <td key={indexi}>{labels[result.gender][language["language"]]}</td>
                                                <td className="name" key={indexi+1}><Link to={"/athletes/" + result.athleteId}>{addName(result)}</Link></td>
                                                <td key={indexi+3}>{
                                                    labels[result.type][language["language"]] + "-" +
                                                    labels[result.division][language["language"]] + "-" +
                                                    result.ac.toUpperCase()
                                                    }
                                                </td>
                                                <td key={indexi+2}>{result["bw"]}</td>
                                                <td key={indexi+6}>{result["wc"]}</td>
                                                <TableEntry index={indexi+7} weight={result.s1} />
                                                <TableEntry index={indexi+8} weight={result.s2} />
                                                <TableEntry index={indexi+9} weight={result.s3} />
                                                <TableEntry index={indexi+10} weight={result.b1} />
                                                <TableEntry index={indexi+11} weight={result.b2} />
                                                <TableEntry index={indexi+12} weight={result.b3} />
                                                <TableEntry index={indexi+13} weight={result.d1} />
                                                <TableEntry index={indexi+14} weight={result.d2} />
                                                <TableEntry index={indexi+15} weight={result.d3} />
                                                <TableEntry index={indexi+16} weight={result.total} />
                                                <TableEntry index={indexi+17} weight={Math.round(result.gl * 100) / 100} />
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

        </div>
    );
}

export default Results