import './documents.css';

import {Link} from 'react-router-dom'
import {useLayoutEffect, useRef, useState} from 'react'

import adhesion from '../assets/adhesion-de-club-2024.pdf';
import bylaws from '../assets/reglements-generaux.pdf';
import declaration from '../assets/declaration-de-services.pdf';
import pdps from '../assets/pdps.pdf';
import plan from '../assets/plan_strategique.pdf';
import politics from '../assets/politiques.pdf';
import rules from '../assets/reglements-techniques.pdf';
import securite from '../assets/reglement-securite.pdf';

import pv2019 from '../assets/pv/pv-2019.pdf';
import pv2022 from '../assets/pv/pv-2022.pdf';
import pv2023 from '../assets/pv/pv-2023.pdf';
import pv2024 from '../assets/pv/pv-2024.pdf';

import rapport2020 from '../assets/rapports/rapport-2020.pdf';
import rapport2021 from '../assets/rapports/rapport-2021.pdf';
import rapport2022 from '../assets/rapports/rapport-2022.pdf';
import rapport2023 from '../assets/rapports/rapport-2023.pdf';

const Documents = ({language}) => {

    const organism = useRef();
    const organismButton = useRef();

    const reports = useRef();
    const reportsButton = useRef();

    const aga = useRef();
    const agaButton = useRef();

    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [labels] = useState({
        "adhesion": {"french": "Adhésion de club", "english":"Club Membership"},
        "aga": {"french": "Assemblées Générales Annuelles", "english":"Annual General Assemblies"},
        "bylaws": {"french": "Règlements généraux", "english":"Bylaws"},
        "declaration": {"french": "Déclaration de services", "english":"Declaration of Services"},
        "tech-rules": {"french": "Règlements techniques", "english":"Technical Rules"},
        "documents": {"french": "DOCUMENTS", "english":"DOCUMENTS"},
        "mission": {"french": "mission", "english":"mission"},
        "organization": {"french": "Organisme", "english": "Organization"},
        "pdps": {"french": "Plan de développement de la pratique sportive", "english":"Sports practice development plan"},
        "plan": {"french": "Plan stratégique", "english": "Strategic Plan"},
        "politics": {"french": "Politiques", "english": "Politics"},
        "pv2019": {"french": "Procès Verbal 2019", "english": "Minutes 2019"},
        "pv2022": {"french": "Procès Verbal 2022", "english": "Minutes 2022"},
        "pv2023": {"french": "Procès Verbal 2023", "english": "Minutes 2023"},
        "pv2024": {"french": "Procès Verbal 2024", "english": "Minutes 2024"},
        "reports": {"french": "Rapport Annuels", "english": "Annual Reports"},
        "report2020": {"french": "Rapport Annuel 2020", "english": "Annual Report 2020"},
        "report2021": {"french": "Rapport Annuel 2021", "english": "Annual Report 2021"},
        "report2022": {"french": "Rapport Annuel 2022", "english": "Annual Report 2022"},
        "report2023": {"french": "Rapport Annuel 2023", "english": "Annual Report 2023"},
        "securite": {"french": "Règlement de sécurité", "english": "Safety Regulations"},
        "integrite": {"french": "Politique, règles et procédures en matière de protection de l'intégrité", "english": "Integrity Protection Policy, Rules and Procedures"}
    })

    return (
        <div className="documents-container">
            <h1>{labels['documents'][language["language"]]}</h1>
            <div className="bubble-container">

                <div className="top-header" onClick={() => onClick(organismButton, organism)}>
                    <h3>{labels['organization'][language["language"]]}</h3>
                    <button ref={organismButton}>&#43;</button>
                </div>

                <div ref={organism} className="content-wrapper-off">
                    <div className="documents-list-container">
                        <ul>
                            <li><a href={adhesion} download>{labels['adhesion'][language["language"]]}</a></li>
                            <li><a href={declaration} download>{labels['declaration'][language["language"]]}</a></li>
                            <li><a href={pdps} download>{labels['pdps'][language["language"]]}</a></li>
                            <li><a href={plan} download>{labels['plan'][language["language"]]}</a></li>
                            <li><Link to="/complaint">{labels['integrite'][language["language"]]}</Link></li>
                            <li><a href={politics} download>{labels['politics'][language["language"]]}</a></li>
                            <li><a href={securite} download>{labels['securite'][language["language"]]}</a></li>
                            <li><a href={bylaws} download>{labels['bylaws'][language["language"]]}</a></li>
                            <li><a href={rules} download>{labels['tech-rules'][language["language"]]}</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(agaButton, aga)}>
                    <h3>{labels['aga'][language["language"]]}</h3>
                    <button ref={agaButton}>&#43;</button>
                </div>
                <div ref={aga} className="content-wrapper-off">
                <div className="documents-list-container">
                        <ul>
                            <li><a href={pv2019} download>{labels['pv2019'][language["language"]]}</a></li>
                            <li><a href={pv2022} download>{labels['pv2022'][language["language"]]}</a></li>
                            <li><a href={pv2023} download>{labels['pv2023'][language["language"]]}</a></li>
                            <li><a href={pv2024} download>{labels['pv2024'][language["language"]]}</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(reportsButton, reports)}>
                    <h3>{labels['reports'][language["language"]]}</h3>
                    <button ref={reportsButton}>&#43;</button>
                </div>
                <div ref={reports} className="content-wrapper-off">
                <div className="documents-list-container">
                        <ul>
                            <li><a href={rapport2020} download>{labels['report2020'][language["language"]]}</a></li>
                            <li><a href={rapport2021} download>{labels['report2021'][language["language"]]}</a></li>
                            <li><a href={rapport2022} download>{labels['report2022'][language["language"]]}</a></li>
                            <li><a href={rapport2023} download>{labels['report2023'][language["language"]]}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Documents
