import './hamburger.css';
import logo from './assets/all-white.png'
import {Link} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'

const Hamburger = ({language, setLanguage}) => {

    useEffect(() => {
        let x = getCookie("language");
        if(x === "") {
            setCookie("language", "french", 3000);
        } else {
            setLanguage({"language": x});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const changeLanguage = () => {
        if(language.language === "french") {
            setLanguage({"language": "english"});
            setCookie("language", "english", 3000)
        } else {
            setLanguage({"language": "french"});
            setCookie("language", "french", 3000)
        }
    };

    const athletes = useRef();
    const competitions = useRef();
    const organization = useRef();
    const resources = useRef();

    const onClick = (div) => {
        let currentClassname = div.current.className;
        athletes.current.className = "item-off";
        competitions.current.className = "item-off";
        organization.current.className = "item-off";
        resources.current.className = "item-off";
        if(currentClassname === "item-off") {
            div.current.className = "item-on";
        } else {
            currentClassname = "item-off";
        }
    };

    const [labels] = useState({
        "switch-language": {"french": "ENGLISH", "english": "FRANÇAIS"},
        "membership": {"french": "DEVENIR MEMBRE", "english": "MEMBERSHIP"},
        "athletes": {"french": "LISTE DES ATHLÈTES", "english": "LIST OF ATHLETES"},
        "donation": {"french": "FAIRE UN DON", "english": "MAKE A DONATION"},
        "donation-link": {"french": "https://www.jedonneenligne.org/sportsquebec/PLSPLDYN/", "english": "https://www.imakeanonlinedonation.org/sportsquebec/PLSPLDYN/"},
        "rankings": {"french": "CLASSEMENTS", "english": "RANKINGS"},
        "clubs": {"french": "LISTE DES CLUBS", "english": "LIST OF CLUBS"},
        "aoty": {"french": "ATHLÈTE DE L'ANNÉE", "english": "ATHLETE OF THE YEAR"},
        "results": {"french": "RÉSULTATS DE COMPÉTITION", "english": "COMPETITION RESULTS"},
        "meets-to-come": {"french": "COMPÉTITIONS À VENIR", "english": "UPCOMING COMPETITIONS"},
        "records": {"french": "RECORDS PROVINCIAUX", "english": "PROVINCIAL RECORDS"},
        "standards": {"french": "NORMES DE QUALIFICAITON", "english": "QUALIFICAITON STANDARDS"},
        "about": {"french": "À PROPOS", "english": "ABOUT"},
        "news": {"french": "NOUVELLES", "english": "NEWS"},
        "rules": {"french": "CONSTITUTION ET RÈGLEMENTS", "english": "CONSTITUTION AND BY-LAWS"},
        "referees": {"french": "ARBITRES", "english": "REFEREES"},
        "help": {"french": "GUIDE DE L'ATHLÈTE", "english": "ATHLETE GUIDE"},
        "anti-doping": {"french": "ANTIDOPAGE", "english": "ANTI-DOPING"},
        "organize-meet": {"french": "ORGANISER UNE COMPÉTITION", "english": "ORGANIZE A COMPETITION"},
        "athletes-menu": {"french": "ATHLÈTES", "english": "ATHLETES"},
        "competitions": {"french": "COMPÉTITIONS", "english": "COMPETITIONS"},
        "organization": {"french": "ORGANISATION", "english": "ORGANIZATION"},
        "resources": {"french": "RESSOURCES", "english": "RESOURCES"},
        "complaint": {"french": "JE PORTE PLAINTE", "english": "I FILE A COMPLAINT"},
        "merchandise": {"french": "ARTICLES PROMOTIONNELS", "english": "MERCHANDISE"}
    });

    return (
        <div className="hamburger-container">
            <Link to="/" className="text">
                <img src={logo} alt="" className="logo" />
            </Link>

            <div className="links">
                <Link to="/hamburger" onClick={() => onClick(athletes)} className="text">{labels['athletes-menu'][language["language"]]}</Link>
                <div ref={athletes} className="item-off">
                    <div className="line">&#65372;</div>
                    <Link to="/athletes" className="link">{labels['athletes'][language["language"]]}</Link>
                    <Link to="/rankings" className="link">{labels['rankings'][language["language"]]}</Link>
                    <Link to="/clubs" className="link">{labels['clubs'][language["language"]]}</Link>
                    <Link to="/athlete-of-the-year" className="link">{labels['aoty'][language["language"]]}</Link>
                </div>

                <Link to="/hamburger" onClick={() => onClick(competitions)} className="text top-spacing">{labels['competitions'][language["language"]]}</Link>
                <div ref={competitions} className="item-off">
                    <div className="line">&#65372;</div>
                    <Link to="/results" className="link">{labels['results'][language["language"]]}</Link>
                    <Link to="/meets" className="link">{labels['meets-to-come'][language["language"]]}</Link>
                    <Link to="/records" className="link">{labels['records'][language["language"]]}</Link>
                    <Link to="/standards" className="link">{labels['standards'][language["language"]]}</Link>
                </div>

                <Link to="/hamburger" onClick={() => onClick(organization)} className="text top-spacing">{labels['organization'][language["language"]]}</Link>
                <div ref={organization} className="item-off">
                    <div className="line">&#65372;</div>
                    <Link to="/about" className="link">{labels['about'][language["language"]]}</Link>
                    <Link to="/news" className="link">{labels['news'][language["language"]]}</Link>
                    <Link to="/documents" className="link">DOCUMENTS</Link>
                    <Link to="/referees" className="link">{labels['referees'][language["language"]]}</Link>
                </div>

                <Link to="/hamburger"onClick={() => onClick(resources)} className="text top-spacing">{labels['resources'][language["language"]]}</Link>
                <div ref={resources} className="item-off">
                    <div className="line">&#65372;</div>
                    <Link to="/guide" className="link">{labels['help'][language["language"]]}</Link>
                    <Link to="/anti-doping" className="link">{labels['anti-doping'][language["language"]]}</Link>
                    <Link to="/organize-meet" className="link">{labels['organize-meet'][language["language"]]}</Link>
                    <Link to="/complaint" className="link">{labels['complaint'][language["language"]]}</Link>
                    {/* <Link to="/merchandise" className="link">{labels['merchandise'][language["language"]]}</Link> */}
                </div>
            </div>
            <Link to="/membership" className="membership">{labels['membership'][language["language"]]}</Link>
            <a target="_blank" rel="noopener noreferrer" href={labels['donation-link'][language["language"]]}>{labels['donation'][language["language"]]}</a>
            <Link to="/hamburger" className="language" onClick={changeLanguage}>{labels['switch-language'][language["language"]]}</Link>
        </div>
    );
}

export default Hamburger
