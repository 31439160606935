import './participants.css';
import {Link} from 'react-router-dom'
import {useState, useEffect, useLayoutEffect } from 'react'

const Participants = ({language, meetId}) => {

    const [limit, setLimit] = useState(0);
    const [participants, setParticipants] = useState([]);
    const [sortType, setSortType] = useState('any');

    const [labels] = useState({
        "participants": {"french": "LISTE DES PARTICIPANTS", "english": "LIST OF PARTICIPANTS"},
        "name": {"french": "Nom", "english": "Name"},
        "last": {"french": "Nom", "english": "Last name"},
        "first": {"french": "Prénom", "english": "First name"},
        "gender": {"french": "Genre", "english": "Gender"},
        "ac": {"french": "Classe d'âge", "english": "Age class"},
        "wc": {"french": "Classe de poids", "english": "Weight class"},
        "M": {"french": "Hommes", "english": "Men"},
        "F": {"french": "Femmes", "english": "Women"},
        "Dynamophilie": {"french": "Dynamophilie", "english": "Powerlifting"},
        "Bench Press": {"french": "Bench Press", "english": "Bench Press"},
        "Classique": {"french": "Classique", "english": "Classic"},
        "Équipé": {"french": "Équipé", "english": "Equipped"}
    });

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId + "/participants", {
            crossDomain:true,
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              const result = myJson.filter(participant => participant.status === "completed");
              setParticipants(result);
          }).catch(function (e) {
              console.log(e);
        });
        // eslint-disable-next-line

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId + "/cap", {
            crossDomain:true,
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              setLimit(myJson.cap);
          }).catch(function (e) {
              console.log(e);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const sortArray = (sortType) => {
            if(sortType === "wcDown" || sortType === "wcUp") {
                const sorted = [...participants].sort((a, b) => {
                    var awc = parseInt(a.wc.substring(1, a.wc.lastIndexOf(" ")));
                    var bwc = parseInt(b.wc.substring(1, b.wc.lastIndexOf(" ")));
    
                    let value = (sortType === "wcDown" ? 1 : -1);
                    if (awc < bwc) {
                        return -value;
                    };
    
                    if (awc > bwc) {
                        return value;
                    };
    
                    if (awc === bwc) {

                        if (sortType === "wcDown") {
                            if (a.wc.substring(0, 1) === "-") {
                                awc *= -value;
                            }
            
                            if (b.wc.substring(0, 1) === "-") {
                                bwc *= -value;
                            }
                        } else {
                            if (a.wc.substring(0, 1) === "-") {
                                awc *= value;
                            }
            
                            if (b.wc.substring(0, 1) === "-") {
                                bwc *= value;
                            }
                        }
                        if (awc < bwc) {return -value;}
                        if (awc > bwc) {return value;}
                    };
                });
                setParticipants(sorted);
            } else if (sortType === "genderDown" || sortType === "genderUp") {
                const sorted = [...participants].sort((a, b) => {
                    let value = sortType === "genderDown" ? 1 : -1;
                    if (a.gender < b.gender) {
                        return -value;
                    };
    
                    if (a.gender > b.gender) {
                        return value;
                    };

                    return 0;
                });
                setParticipants(sorted);
            } else if (sortType === "acDown" || sortType === "acUp") {
                const sorted = [...participants].sort((a, b) => {
                    let value = sortType === "acDown" ? 1 : -1;
                    if (a.ac < b.ac) {
                        return -value;
                    };
    
                    if (a.ac > b.ac) {
                        return value;
                    };

                    return 0;
                });
                setParticipants(sorted);
            } else if (sortType === "nameDown" || sortType === "nameUp") {
                const sorted = [...participants].sort((a, b) => {
                    let value = sortType === "nameDown" ? 1 : -1;
                    if (a.last.toLowerCase() < b.last.toLowerCase()) {
                        return -value;
                    };
    
                    if (a.last.toLowerCase() > b.last.toLowerCase()) {
                        return value;
                    };

                    return 0;
                });
                setParticipants(sorted);
            } else if (sortType === "typeDown" || sortType === "typeUp") {
                const sorted = [...participants].sort((a, b) => {
                    let value = sortType === "typeDown" ? 1 : -1;
                    if (a.type < b.type) {
                        return -value;
                    };
    
                    if (a.type > b.type) {
                        return value;
                    };

                    return 0;
                });
                setParticipants(sorted);
            } else if (sortType === "divisionDown" || sortType === "divisionUp") {
                const sorted = [...participants].sort((a, b) => {
                    let value = sortType === "divisionDown" ? 1 : -1;
                    if (a.division < b.division) {
                        return -value;
                    };
    
                    if (a.division > b.division) {
                        return value;
                    };

                    return 0;
                });
                setParticipants(sorted);
            }
        };
        sortArray(sortType);
    }, [sortType]);

    return (
        <div className="participants-container">
            <h2>{labels['participants'][language["language"]]}</h2>

            <p>Participants: {participants.length} / {limit}</p>
            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th className="pointer" onClick={() => setSortType(sortType === "nameDown" ? "nameUp" : "nameDown")}>{labels['name'][language["language"]]}</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "genderDown" ? "genderUp" : "genderDown")}>{labels['gender'][language["language"]]}</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "acDown" ? "acUp" : "acDown")}>{labels['ac'][language["language"]]}</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "typeDown" ? "typeUp" : "typeDown")}>Type</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "divisionDown" ? "divisionUp" : "divisionDown")}>Division</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "wcDown" ? "wcUp" : "wcDown")}>{labels['wc'][language["language"]]}</th>
                            </tr>

                            { 
                            // eslint-disable-next-line
                                participants.map((participant, index) => {
                                    return (                
                                        <tr key={index}>
                                            <td key={index+1}><Link to={"/athletes/" + participant.athleteId} key={index+2}>{participant["first"] + " " + participant["last"]}</Link></td>
                                            <td key={index+3}>{labels[participant["gender"]][language["language"]]}</td>
                                            <td key={index+4}>{participant["ac"]}</td>
                                            <td key={index+5}>{labels[participant["type"]][language["language"]]}</td>
                                            <td key={index+6}>{labels[participant["division"]][language["language"]]}</td>
                                            <td key={index+7}>{participant["wc"]}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Participants
