import './news.css';

import {useLayoutEffect, useState} from 'react'

import eoy2022fr from '../assets/fin-annee-2022-fr.pdf';
import eoy2022en from '../assets/fin-annee-2022-en.pdf';
import letter from '../assets/lettre2020.pdf';
import letter2 from '../assets/lettre2021.pdf';
import letter2023 from '../assets/lettre2023.pdf';
import letter3 from '../assets/dg-2022.pdf';
import letter4 from '../assets/president-2022.pdf';

const News = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [labels] = useState({
        "news": {"french": "NOUVELLES", "english":"NEWS"},
        "title": {"french": "Lettre de fin d'année 2020", "english":"End of year letter"},
        "title2": {"french": "Lettre de fin d'année 2021", "english":"End of year letter"},
        "title2023": {"french": "Lettre de fin d'année 2023", "english":"End of year letter"},
        "title3": {"french": "Nouvelle administration", "english":"New administration"},
        "title4": {"french": "Lettre de fin d'année 2022", "english":"End of year letter"},
        "date": {"french": "16 janvier 2021", "english":"January 16, 2021"},
        "date2": {"french": "30 décembre 2021", "english":"December 30, 2021"},
        "date2023": {"french": "19 décembre 2023", "english":"December 19, 2023"},
        "date3": {"french": "19 avril 2022", "english":"April 19, 2022"},
        "date4": {"french": "22 décembre 2022", "english":"December 22, 2022"},
        "content": {"french": [<a key={1} href={letter} download>Cliquer ici</a>,  " pour lire la lettre aux membres de notre président Nicolas Déry."],
                    "english": [<a key={2} href={letter} download>Click here</a>,  " to read the letter to members from our president Nicolas Déry."]},
        "content2": {"french": [<a key={3} href={letter2} download>Cliquer ici</a>,  " pour lire la lettre aux membres de notre président Nicolas Déry."],
                    "english": [<a key={4} href={letter2} download>Click here</a>,  " to read the letter to members from our president Nicolas Déry."]},
        "content2023": {"french": [<a key={3} href={letter2023} download>Cliquer ici</a>,  " pour lire la lettre aux membres de notre directeur général Nicolas Déry."],
                    "english": [<a key={4} href={letter2023} download>Click here</a>,  " to read the letter to members from our general manager Nicolas Déry."]},
        "content3": {"french": [<a key={5} href={letter4} download>Cliquer ici</a>,  " pour lire la lettre aux membres de notre nouveau président Jérémie Borgia et ", 
                        <a key={6} href={letter3} download>cliquer ici</a>, " pour lire la lettre aux membres de notre nouveau directeur-général Nicolas Déry."],
                    "english": [<a key={7} href={letter4} download>Click here</a>,  " to read the letter to members from our new president Jérémie Borgia and ",
                        <a key={8} href={letter3} download>click here</a>, " to read the letter to members from our new general manager Nicolas Déry."]},
        "content4": {"french": [<a key={9} href={eoy2022fr} download>Cliquer ici</a>,  " pour lire la lettre aux membres de notre directeur général Nicolas Déry."],
                        "english": [<a key={10} href={eoy2022en} download>Click here</a>,  " to read the letter to members from our general manager Nicolas Déry."]},
    })

    return (
        <div className="news-page-container">
            <h1>{labels['news'][language["language"]]}</h1>
            <div className="news-container">
                <div className="news">
                    <div className="text-container">
                        <p>{labels['date2023'][language["language"]]}</p>
                        <h2>{labels['title2023'][language["language"]]}</h2>
                        <p className="text">{labels['content2023'][language["language"]]}</p>
                    </div>
                </div>
                <div className="news">
                        <div className="text-container">
                            <p>{labels['date4'][language["language"]]}</p>
                            <h2>{labels['title4'][language["language"]]}</h2>
                            <p className="text">{labels['content4'][language["language"]]}</p>
                        </div>
                </div>
                <div className="news">
                    <div className="text-container">
                        <p>{labels['date3'][language["language"]]}</p>
                        <h2>{labels['title3'][language["language"]]}</h2>
                        <p className="text">{labels['content3'][language["language"]]}</p>
                    </div>
                </div>
                <div className="news">
                    <div className="text-container">
                        <p>{labels['date2'][language["language"]]}</p>
                        <h2>{labels['title2'][language["language"]]}</h2>
                        <p className="text">{labels['content2'][language["language"]]}</p>
                    </div>
                </div>
                <div className="news">
                    <div className="text-container">
                        <p>{labels['date'][language["language"]]}</p>
                        <h2>{labels['title'][language["language"]]}</h2>
                        <p className="text">{labels['content'][language["language"]]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News
