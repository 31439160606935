import Header from './Header';
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import './main.css';

import acostal from './assets/backgrounds/landscape/acosta.jpg'
import acostap from './assets/backgrounds/portrait/acosta.jpg'

import amel from './assets/backgrounds/landscape/ame.jpg'
import amep from './assets/backgrounds/portrait/ame.jpg'

import aureliel from './assets/backgrounds/landscape/aurelie.jpg'
import aureliep from './assets/backgrounds/portrait/aurelie.jpg'

import hugol from './assets/backgrounds/landscape/hugo.jpg'
import hugop from './assets/backgrounds/portrait/hugo.jpg'

import magl from './assets/backgrounds/landscape/mag.jpg'
import magp from './assets/backgrounds/portrait/mag.jpg'

import mariannel from './assets/backgrounds/landscape/marianne.jpg'
import mariannep from './assets/backgrounds/portrait/marianne.jpg'

const Main = ({language, setLanguage, title, date, content}) => {

    const [labels] = useState({"featured-athlete": {"french": "ATHLÈTE EN VEDETTE", "english": "FEATURED ATHLETE"},
                                            "description": {"french": "CHAMPIONNE CANADIENNE JUNIOR", "english": "CANADIAN JUNIOR CHAMPION"},
                                            "news": {"french": "NOUVELLES", "english": "NEWS"},
                                            "read-more": {"french": "Lire la suite", "english": "Read more"},
                                            "coming-competitions": {"french": "COMPÉTITIONS À VENIR", "english": "UPCOMING COMPETITIONS"},
                                            "registration": {"french": "INSCRIPTION", "english": "REGISTRATION"},
                                            "no-competition-title": {"french": "Aucune compétition à afficher pour l'instant", "english": "No competition to display for the moment",},
                                            "date": {"french": "2 AVRIL 2022", "english": "APRIL 2 2022",},
                                            "world-junior-champion-f": {
                                                "french": "CHAMPIONNE MONDIALE JUNIOR", 
                                                "english": "WORLD JUNIOR CHAMPION"},
                                            "national-junior-champion-f": {
                                                "french": "CHAMPIONNE NATIONALE JUNIOR", 
                                                "english": "NATIONAL JUNIOR CHAMPION"},
                                            "national-junior-champion-m": {
                                                "french": "CHAMPION NATIONAL JUNIOR", 
                                                "english": "NATIONAL JUNIOR CHAMPION"},
                                            "national-champion-m": {
                                                "french": "CHAMPION NATIONAL", 
                                                "english": "NATIONAL CHAMPION"},
                                            "national-master-m": {
                                                "french": "CHAMPION NATIONAL MASTER", 
                                                "english": "MASTER NATIONAL CHAMPION"},
                                            "provincial-champion-f": {
                                                "french": "CHAMPIONNE PROVINCIALE", 
                                                "english": "PROVINCIAL CHAMPION"},
                                            "provincial-champion-m": {
                                                "french": "CHAMPION PROVINCIAL",
                                                "english": "PROVINCIAL CHAMPION"},
                                            "deadlift-world-record-holder-m": {
                                                "french": "CHAMPION NATIONAL", 
                                                "english": "NATIONAL CHAMPION"},
                                            "top-gl-m": {
                                                "french": "MEILLEUR SCORE GL FQD", 
                                                "english": "HIGHEST FQD GL SCORE"},
                                            "junior-athlete-m": {
                                                "french": "PODIUM AUX CHAMPIONNATS PROVINCIAUX ET NATIONAUX", 
                                                "english": "PODIUM AT PROVINCIAL AND NATIONAL CHAMPIONSHIPS"}
                                        });

    const [meets, setMeets] = useState([]);
    const [currentMeetIndex, setCurrentMeetIndex] = useState(0);
    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets?status=active', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                myJson.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
                setMeets(myJson);
            });
    // eslint-disable-next-line
    }, []);

    const [backgrounds] = useState([
        {
            name: "AMÉLIE PICHER-PLANTE",
            description: "world-junior-champion-f",
            landscape: [amel],
            portrait: [amep],
            id: 782
        },
        {
            name: "CHRISTOPHER ACOSTA-TAPIA",
            description: "national-champion-m",
            landscape: [acostal],
            portrait: [acostap],
            id: 1220
        },
        {
            name: "AURÉLIE NGUYEN",
            description: "world-junior-champion-f",
            landscape: [aureliel],
            portrait: [aureliep],
            id: 1093
        },
        {
            name: "HUGO BOUTHIETTE",
            description: "national-champion-m",
            landscape: [hugol],
            portrait: [hugop],
            id: 1016
        },
        {
            name: "MARC-ANTOINE GOSSELIN",
            description: "national-champion-m",
            landscape: [magl],
            portrait: [magp],
            id: 511
        },
        {
            name: "MARIANNE GROLEAU",
            description: "national-junior-champion-f",
            landscape: [mariannel],
            portrait: [mariannep],
            id: 737
        }
    ]);
    
    const [recentIndexes, setRecentIndexes] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * backgrounds.length));
    const [nextIndex, setNextIndex] = useState(Math.floor(Math.random() * backgrounds.length));
    useEffect(() => {
        const timerId = setInterval(() => {
            if (recentIndexes.length === 0) {
                recentIndexes.push(currentIndex);
            }
            if (recentIndexes.length === 5) {
                recentIndexes.shift();
            }
            recentIndexes.push(nextIndex);
            setRecentIndexes(recentIndexes);
            setCurrentIndex(nextIndex);

            let newIndex = Math.floor(Math.random() * backgrounds.length);
            while(recentIndexes.includes(newIndex)) {
                newIndex = Math.floor(Math.random() * backgrounds.length);
            }
            setNextIndex(newIndex);
            onClickNext();
        }, 5000);
        return () => clearInterval(timerId);
    });

    const returnBackground = () => {
        if(window.matchMedia("(orientation: portrait)").matches) {
            return ({
                "backgroundImage": `url(${backgrounds[currentIndex].portrait[0]})`,
                "background-repeat": "no-repeat",
                "backgroundSize": "cover",
                "transition": "background-image 0.5s ease-in-out",
                "WebkitTransition": "background-image 0.5s ease-in-out"
            });
        } else {
            return ({
                "backgroundImage": `url(${backgrounds[currentIndex].landscape[0]})`,
                "backgroundSize": "cover",
                "WebkitTransition": "background-image 0.5s ease-in-out",
                "transition": "background-image 0.5s ease-in-out"
            });
        }
    }

    const onClickNext = () => {
        if(currentMeetIndex < meets.length - 1) {
            setCurrentMeetIndex(currentMeetIndex + 1);
        } else {
            setCurrentMeetIndex(0);
        }
    };

    const onClickPrevious = () => {
        if(currentMeetIndex === 0) {
            setCurrentMeetIndex(meets.length - 1);
        } else {
            setCurrentMeetIndex(currentMeetIndex - 1);
        }
    };

    return (
    <main>
        <div className="component-container" style={returnBackground()}>
            <Header language={language} setLanguage={setLanguage} />
            <div className="main-container">
                <div className="athlete-container">
                    <div className="news">
                        <p className="newstitle">{labels['featured-athlete'][language["language"]]}</p>
                        <div className="main-line"></div>
                        <div className="news-container">
                            <Link key={1} to={"/athletes/" + backgrounds[currentIndex].id} className="title athlete-hover">{backgrounds[currentIndex].name}</Link>
                            <p className="date">{labels[backgrounds[currentIndex].description][language["language"]]}</p>
                        </div>
                    </div>
                </div>
                <div className="news-competitions-container">
                    <div className="news">
                        <p className="newstitle">{labels['news'][language["language"]]}</p>
                        <div className="main-line"></div>
                        <div className="news-container">
                            <p className="title">{title[language["language"]]}</p>
                            <p className="date">{date[language["language"]]}</p>
                            <p className="preview">{content[language["language"]]}</p>
                            <p className="read-more">Lire la suite</p>
                        </div>
                    </div>

                    {
                        meets.length > 0 &&<div className="competitions-container">
                            <p className="newstitle">{labels['coming-competitions'][language["language"]]}</p>
                            <div className="competition-container">
                                <div className="main-line"></div>
                                <div className="competition">
                                    <div className="arrow" onClick={() => onClickPrevious()}><span className="competition-arrow">&#60;</span></div>
                                    <div className="competition-content">
                                        <p className="competition-title">{meets[currentMeetIndex].nom}</p>
                                        <p className="info">{meets[currentMeetIndex].location}</p>
                                        <p className="info">{meets[currentMeetIndex].date.substring(0, 10)}</p>
                                    </div>
                                    <div className="arrow" onClick={() => onClickNext()}><span className="competition-arrow">&#62;</span></div>
                                </div>
                                <Link key={2} to={"/meets"} className="inscription">{labels['registration'][language["language"]]}</Link>
                            </div>

                            <div className="no-competition-container">
                                <div className="main-line"></div>
                                <div className="competition">
                                    <div className="competition-content">
                                        <p className="competition-title">{labels['no-competition-title'][language["language"]]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <img src={backgrounds[nextIndex].landscape[0]} className="preload" alt=""/>
            <img src={backgrounds[nextIndex].portrait[0]} className="preload" alt=""/>
        </div>
    </main>
    );
}

export default Main;
