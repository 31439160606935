import './documents.css';

import {Link} from 'react-router-dom'
import {useLayoutEffect, useState} from 'react'

import adhesion from '../assets/adhesion-de-club-2024.pdf';
import bylaws from '../assets/reglements-generaux.pdf';
import pdps from '../assets/pdps.pdf';
import plan from '../assets/plan_strategique.pdf';
import politics from '../assets/politiques.pdf';
import rules from '../assets/reglements-techniques.pdf';
import securite from '../assets/reglement-securite.pdf';

const Documents = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [labels] = useState({
        "adhesion": {"french": "Adhésion de club", "english":"Club Membership"},
        "bylaws": {"french": "Règlements généraux", "english":"Bylaws"},
        "tech-rules": {"french": "Règlements techniques", "english":"Technical Rules"},
        "documents": {"french": "DOCUMENTS", "english":"DOCUMENTS"},
        "organization": {"french": "Organisme", "english": "Organization"},
        "pdps": {"french": "Plan de développement de la pratique sportive", "english":"Sports practice development plan"},
        "plan": {"french": "Plan stratégique", "english": "Strategic Plan"},
        "politics": {"french": "Politiques", "english": "Politics"},
        "securite": {"french": "Règlement de sécurité", "english": "Safety Regulations"},
        "integrite": {"french": "Politique, règles et procédures en matière de protection de l'intégrité", "english": "Integrity Protection Policy, Rules and Procedures"}
    })

    return (
        <div className="documents-container">
            <h1>{labels['documents'][language["language"]]}</h1>
            <div className="bubble-container">
                <div className="top-header">
                    <h3>{labels['organization'][language["language"]]}</h3>
                </div>

                <div className="content-wrapper-on">
                    <ul>
                        <li><a href={adhesion} download>{labels['adhesion'][language["language"]]}</a></li>
                        <li><a href={pdps} download>{labels['pdps'][language["language"]]}</a></li>
                        <li><a href={plan} download>{labels['plan'][language["language"]]}</a></li>
                        <li><Link to="/complaint">{labels['integrite'][language["language"]]}</Link></li>
                        <li><a href={politics} download>{labels['politics'][language["language"]]}</a></li>
                        <li><a href={securite} download>{labels['securite'][language["language"]]}</a></li>
                        <li><a href={bylaws} download>{labels['bylaws'][language["language"]]}</a></li>
                        <li><a href={rules} download>{labels['tech-rules'][language["language"]]}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Documents
