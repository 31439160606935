import './certificate.css';
import Pdf from "react-to-pdf";
import {createRef} from 'react';

import logo from '../assets/blue.png'

const Certificate = ({athlete, weight, lift, division, age, wc, meet, date}) => {

    const ref = createRef();

    const options = {
        orientation: 'landscape'
    };

    return (
        <>
            <Pdf targetRef={ref} filename="certificat.pdf" options={options} x={6} y={-4.5}>
                {({toPdf}) => (
                    <button className="download" onClick={toPdf}>PDF</button>
                )}
            </Pdf>
            <div ref={ref} className="certificate-container">
                <div className="certificate-outer-container" />
                <div className="certificate-inner-container" />
                <img src={logo} alt=""/>
                <p  className="p1">Ce certificat est décerné à</p>
                <h1>{athlete}</h1>
                
                <p className="p2">pour un record provincial de</p>
                <h2>{weight.replace('.', ",")} kg au {lift}</h2>
                <div className="line" />

                <div className="division">{division}</div>
                <div className="division-label">Division</div>

                <div className="age">{age}</div>
                <div className="age-label">Catégorie d’âge</div>

                <div className="weight">{wc}</div>
                <div className="weight-label">Classe de poids</div>

                <div className="meet">{meet}</div>
                <div className="meet-label">Compétition</div>

                <div className="signature">Nicolas Déry</div>
                <div className="small-line1"/>
                <div className="title">Nicolas Déry, Président</div>

                <div className="date">{date.replace(/-/g, "/")}</div>
                <div className="small-line2"/>
                <div className="date-label">Date du record</div>
            </div>
        </>
    );
}

export default Certificate
