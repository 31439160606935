import './athlete.css';
import {Link} from 'react-router-dom';
import {useEffect, useState, useLayoutEffect } from 'react'

const Athlete = ({language, id}) => {

    const [labels] = useState({
        "pl": {"french": "D", "english": "P"},
        "bp": {"french": "B", "english": "Bench"},
        "cl": {"french": "C", "english": "C"},
        "eq": {"french": "E", "english": "E"},

        "athletes": {"french": "LISTE DES ATHLÈTES", "english": "LIST OF ATHLETES"},
        "results": {"french": "MEILLEURS RÉSULTATS", "english": "BEST RESULTS"},
        "name": {"french": "Nom", "english": "Name"},
        "sex": {"french": "Sexe", "english": "Sex"},
        "age": {"french": "Classe d'âge", "english": "Age Class"},
        "weight": {"french": "Poids Corporel", "english": "Weight"},
        "weight-class": {"french": "Classe de poids", "english": "Weight Class"},
        "competition": {"french": "Compétition", "english": "Competition"},
        "o": "Open",
        "j": "Junior",
        "sj": "Junior",
        "m1": "Master I",
        "m2": "Master II",
        "m3": "Master III",
        "m4": "Master IV"
    })

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [athlete, setAthlete] = useState( { 0: [] });
    const [clubs, setClubs] = useState({0: 0});
    const [results, setResults] = useState( { 0: [] });

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/athletes/' + id, {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                setAthlete(myJson);
            });

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/clubs/', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                let clubs = {};
                myJson.forEach(element => {
                    clubs[element.clubId] = element.name;
                });
                setClubs(clubs);
            });

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                let meets = {};
                myJson.forEach(element => {
                    meets[element.meetId] = element;
                });

                fetch('https://sheltered-inlet-15640.herokuapp.com/api/athletes/' + id + '/results', {
                    headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    }
                }).then(function(response){
                    return response.json();
                    }).then(function(myJson) {
                        myJson.forEach(element => {
                            element.date = meets[element.meetId].date.substring(0, 10);
                            element.meet = meets[element.meetId].nom;
                        });
                        setResults(myJson);
                    });
            });
    // eslint-disable-next-line
    }, []);

    return (
        <div className="athlete-profile-container">
            <h1>{athlete.first + ' ' + athlete.last}</h1>

            {
                'club' in athlete && <Link className="club" to={"/clubs/" + athlete.club}>{clubs[athlete.club]}</Link>
            }

            <h2>{labels['results'][language["language"]]}</h2>

            <div className="table-container">
                <div className="table-wrapper">
                    <table className="results">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>{labels['competition'][language["language"]]}</th>
                                <th>Type</th>
                                {/* <th>Division</th> */}
                                {/* <th>{labels['age'][language["language"]]}</th> */}
                                <th>{labels['weight'][language["language"]]}</th>
                                <th>{labels['weight-class'][language["language"]]}</th>
                                <th>Squat</th>
                                <th>Bench Press</th>
                                <th>Deadlift</th>
                                <th>Total</th>
                                <th>GL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                results.length > 0 &&  results.sort(function(a, b) {
                                    return new Date(b["date"]) - new Date(a["date"]);
                                }).map((result, index) => {
                                    return(
                                        <tr key={index}>
                                            <td key={index+12}>{result["date"]}</td>
                                            <td key={index+1}>{result["meet"]}</td>
                                            <td key={index+3}>
                                                {
                                                labels[result["type"]][language["language"]] + "-" +
                                                labels[result["division"]][language["language"]] + "-" +
                                                result["ac"].toUpperCase()
                                                }</td>
                                            <td key={index+2}>{result["bw"]}</td>
                                            <td key={index+6}>{result["wc"]}</td>
                                            <td key={index+7}>{result.squat}</td>
                                            <td key={index+8}>{result.bench}</td>
                                            <td key={index+9}>{result.deadlift}</td>
                                            <td key={index+10}>{result.total}</td>
                                            <td key={index+11}>{(result.gl === "-" || result.gl === 0  ? "-" : Math.round(result.gl * 100) / 100)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Athlete
