import Footer from './components/Footer';
import Hamburger from './components/Hamburger';
import Header from './components/Header';
import Main from './components/Main';

import Membership from './components/Membership';

import Athlete from './components/athletes/Athlete';
import AthleteOfTheYear from './components/athletes/AthleteOfTheYear'
import Athletes from './components/athletes/Athletes';
import Club from './components/athletes/Club';
import Clubs from './components/athletes/Clubs';
import Rankings from './components/athletes/Rankings';

import Confirmation from './components/meets/Confirmation';
import Meets from './components/meets/Meets'
import Participants from './components/meets/Participants';
import Records from './components/meets/Records'
import Registration from './components/meets/Registration';
import Results from './components/meets/Results';
import Standards from './components/meets/Standards';

import About from './components/organization/About';
import News from './components/organization/News';
import Documents from './components/organization/Documents';
import Referees from './components/organization/Referees';

import AntiDoping from './components/resources/AntiDoping';
import Guide from './components/resources/Guide';
import OrganizeMeet from './components/resources/OrganizeMeet';
import Complaint from './components/resources/Complaint';
import Merchandise from './components/resources/Merchandise';

import letter from './components/assets/lettre2023.pdf';

import Certificate from './components/meets/Certificate'

import {BrowserRouter as Router, Route} from 'react-router-dom';
import {useEffect, useState} from 'react';

import ReactGa from 'react-ga';
import React from 'react';

const App = () => {

  const [language, setLanguage] = useState({"language": "french"})

  useEffect(() => {
    ReactGa.initialize('UA-215784865-1');
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Route path='/' exact render={() => (
        <div>
          <Main  
            language={language} 
            setLanguage={setLanguage} 
            title={{"french": "Lettre de fin d'année",
                    "english":"End of year letter"}} 
            date={{"french": "19 décembre 2023",
                    "english":"December 19, 2023"}}
            content={{"french": [<a key={1} href={letter} download>Cliquer ici</a>,  " pour lire la lettre aux membres de notre directeur général Nicolas Déry."],
                      "english": [<a key={2} href={letter} download>Click here</a>,  " to read the letter to members from our general manager Nicolas Déry."]}}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/certificate/:athlete/:weight/:lift/:division/:age/:wc/:meet/:date' exact render={(props) => (
        <div>
          <Certificate 
            athlete={props.match.params.athlete}
            weight={props.match.params.weight} 
            lift={props.match.params.lift}
            division={props.match.params.division} 
            age={props.match.params.age}
            wc={props.match.params.wc} 
            meet={props.match.params.meet} 
            date={props.match.params.date} 
          />
        </div>
      )} />

      <Route path='/hamburger' exact render={() => (
        <div>
          <Hamburger language={language} setLanguage={setLanguage} />
        </div>
      )} />

      <Route path='/membership' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Membership language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/athletes' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Athletes language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/athletes/:id' exact render={(id) => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Athlete language={language} id={id.match.params.id}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/rankings' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Rankings language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/clubs' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Clubs language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/clubs/:clubId' exact render={(clubId) => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Club language={language} clubId={clubId.match.params.clubId}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/athlete-of-the-year' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <AthleteOfTheYear language={language}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/results' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Results language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/meets' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Meets language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/meets/:meetId/registration' exact render={(meetId) => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Registration language={language} meetId={meetId.match.params.meetId}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/meets/:meetId/participants' exact render={(meetId) => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Participants language={language} meetId={meetId.match.params.meetId}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/meets/:meetId/confirmation' exact render={(meetId) => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Confirmation language={language} meetId={meetId.match.params.meetId}/>
          <Footer language={language} />
        </div>
      )} />

      <Route path='/records' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Records language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/standards' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Standards language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/about' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <About language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/news' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <News language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/documents' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Documents language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/referees' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Referees language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/guide' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Guide language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/anti-doping' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <AntiDoping language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/organize-meet' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <OrganizeMeet language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/complaint' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Complaint language={language} />
          <Footer language={language} />
        </div>
      )} />

      <Route path='/merchandise' exact render={() => (
        <div>
          <Header language={language} setLanguage={setLanguage} />
          <Merchandise language={language} />
          <Footer language={language} />
        </div>
      )} /> 
    </Router>
  )
}

export default App