import './meets.css';
import {useEffect, useLayoutEffect, useState} from 'react'
import {Link} from 'react-router-dom'

const Meets = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const [meets, setMeets] = useState([]);
    const [labels] = useState({"no-upcoming-meets": {"french": "Aucune compétition à afficher pour l'instant",
                                                                "english": "No competition to display for the moment"},
                                            "upcoming-meets": {"french": "COMPÉTITIONS À VENIR",
                                                                "english": "UPCOMING COMPETITIONS"},
                                            "cl-eq": {"french": "Classique et équipé",
                                                                "english": "Classic and equipped"},
                                            "charge": {"french": "Responsable",
                                                                "english": "Person in charge"},
                                            "email": {"french": "Courriel",
                                                                "english": "Email"},
                                            "weighin": {"french": "Heure de la pesée",
                                                                "english": "Weigh In Time"},
                                            "weighin-time": {"french": "8h et 11h30",
                                                                "english": "8am and 11:30am"},
                                            "schedule": {"french": "Horaire",
                                                            "english": "Schedule"},
                                            "comp": {"french": "Heure de la compétition",
                                                                "english": "Competition Time"},
                                            "comp-time": {"french": "10h et 13h30",
                                                                "english": "10am and 13:30pm"},
                                            "deadline": {"french": "Date limite d'inscription",
                                                                "english": "Registration deadline"},
                                            "deadline-date": {"french": "Samedi 24 juillet",
                                                                "english": "Saturday July 24"},
                                            "limit": {"french": "Limite de participants",
                                                                "english": "Limit of participants"},
                                            "upcoming": {"french": "COMPÉTITIONS À VENIR",
                                                                "english": "UPCOMING COMPETITIONS"},
                                            "date": {"french": "21 AOÛT", "english": "AUG 21st"},
                                            "fees": {"french": "Frais d'inscription", "english": "Registration fees"},
                                            "fees-total": {"french": "$ (taxes et frais d'antidopage de 15$ inclus)",
                                                             "english": "$ (taxes and anti-doping fees of $15 included)"},
                                            "registration": {"french": "Inscription", "english": "Registration"},
                                            "participants": {"french": "Liste des participants", "english": "List of participants"},
                                            "01":  {"french": "JAN", "english": "JAN"},
                                            "02":  {"french": "FEV", "english": "FEB"},
                                            "03":  {"french": "MAR", "english": "MAR"},
                                            "04":  {"french": "AVR", "english": "APR"},
                                            "05":  {"french": "MAI", "english": "MAY"},
                                            "06":  {"french": "JUIN", "english": "JUN"},
                                            "07":  {"french": "JUIL", "english": "JUL"},
                                            "08":  {"french": "AOÛT", "english": "AUG"},
                                            "09":  {"french": "SEPT", "english": "SEP"},
                                            "10":  {"french": "OCT", "english": "OCT"},
                                            "11":  {"french": "NOV", "english": "NOV"},
                                            "12":  {"french": "DEC", "english": "DEC"},
                                            "link":  {"french": "Lien", "english": "Link"},
                                            "spectators":  {"french": "spectateurs", "english": "spectators"},
                                            "microwave":  {"french": "Micro-onde", "english": "Microwave"},
                                            "canteen":  {"french": "Cantine (Sushis)", "english": "Canteen (Sushis)"},
                                            "kg":  {"french": "Échauffement en KG", "english": "KG plates in warm-up"},
                                            "lbs":  {"french": "Échauffement en lbs", "english": "LBS plates in warm-up"},
                                            "photographer":  {"french": "Photographe", "english": "Photographer"},
                                });

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets?status=active', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                setMeets(myJson);
            });
    // eslint-disable-next-line
    }, []);

    const onClick = (index) => {
        if (document.getElementById("meet-" + index).className === "details") {
            document.getElementById("meet-" + index).className = "details-off";
        } else {
            document.getElementById("meet-" + index).className = "details";
        }
    };

    if(meets.length !== 0) {
        return (
            <div className="upcoming-meets-container">
                <h2>{labels['upcoming'][language["language"]]}</h2>
                    {
                        meets.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)).map((meet, index) => {
                            return(
                                <div className="outer-container">
                                    <div className="meet-container">
                                        <h1 onClick={() => onClick(index)}>{meet.date.substring(8, 10) + " " + labels[meet.date.substring(5, 7)][language["language"]]}</h1>
                                        <div className="info-container">

                                            <div className="meet-header" onClick={() => onClick(index)}>
                                                <h3>{meet.nom}</h3>
                                                <p className="location">{meet.location}</p>
                                                <p className="location">{meet.address}</p>
                                            </div>

                                            <div id={"meet-" + index} className="details-off">
                                                <div className="detail">
                                                    <p className="detail-label">Division</p>
                                                    <p className="detail-text">{labels['cl-eq'][language["language"]]}</p>
                                                </div>
                                                <div className="detail">
                                                    <p className="detail-label">{labels['charge'][language["language"]]}</p>
                                                    <p className="detail-text">{meet.charge}</p>
                                                </div>
                                                <div className="detail">
                                                    <p className="detail-label">{labels['email'][language["language"]]}</p>
                                                    <p className="detail-text email">{meet.email}</p>
                                                </div>

                                                {                                    
                                                    !(Object.keys(meet).includes("schedule")) &&
                                                    <>
                                                        <div className="detail">
                                                            <p className="detail-label">{labels['weighin'][language["language"]]}</p>
                                                            <p className="detail-text">{meet.weighin}</p>
                                                        </div>
                                                        <div className="detail">
                                                            <p className="detail-label">{labels['comp'][language["language"]]}</p>
                                                            <p className="detail-text">{meet.comp}</p>
                                                        </div>
                                                    </>
                                                }

                                                {                                    
                                                    (Object.keys(meet).includes("schedule")) &&
                                                    <>
                                                        <div className="detail">
                                                            <p className="detail-label">{labels['schedule'][language["language"]]}</p>
                                                            <a target="_blank" rel="noopener noreferrer" href={meet.schedule}>
                                                                <p className="detail-text">{labels['link'][language["language"]]}</p>
                                                            </a>
                                                        </div>
                                                    </>
                                                }

                                                <div className="detail">
                                                    <p className="detail-label">{labels['fees'][language["language"]]}</p>
                                                    <p className="detail-text">{meet.cost + labels['fees-total'][language["language"]]}</p>
                                                </div>
                                                <div className="detail">
                                                    <p className="detail-label">{labels['deadline'][language["language"]]}</p>
                                                    <p className="detail-text">{meet.limit.substring(0, 10)}</p>
                                                </div>
                                                <div className="detail">
                                                    <p className="detail-label">{labels['limit'][language["language"]]}</p>
                                                    <p className="detail-text">{meet.number}</p>
                                                </div>
                                                <div className="detail">
                                                    <p className="detail-label">{'Autres informations'}</p>
                                                    <ul>
                                                        <li><p className="detail-text">&#10003; Live Stream</p></li>
                                                        {
                                                            meet.canteen && <li><p className="detail-text">&#10003; {labels['canteen'][language["language"]]}</p></li>
                                                        }
                                                        <li><p className="detail-text">&#10003; {labels['kg'][language["language"]]}</p></li>
                                                        {
                                                            meet.lbs && <li><p className="detail-text">&#10003; {labels['lbs'][language["language"]]}</p></li>
                                                        }
                                                        {
                                                            meet.photographer && <li><p className="detail-text">&#10003; {labels['photographer'][language["language"]]}</p></li>
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="links">
                                                    { 
                                                        meet.registration === "open" && 
                                                        <Link to={"/meets/" + meet.meetId + "/registration"} className="link blue-background">{labels['registration'][language["language"]]}</Link> 
                                                    }
                                                    <Link className="link" to={"/meets/" + meet.meetId + "/participants/"}>{labels['participants'][language["language"]]}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {                                    
                                        index !== Object.keys(meets).length -1 &&
                                        <div className="line" />
                                    }
                                </div>
                            )
                        })
                    }
            </div>
        );
    } else {
        return (
            <div className="upcoming-meets-container">
                <h2>{labels['upcoming'][language["language"]]}</h2>
                <div className="meet-container-no-result">
                    <div className="no-result">
                        {labels['no-upcoming-meets'][language["language"]]}
                    </div>
                </div>
            </div>
        );
    }
}

export default Meets
