import './table-entry.css';

const MeetsResults = ({index, weight}) => {

    if(weight !== '-' && weight < 0) {
        return (
            <td key={index} className="red-cell">{Math.abs(weight)}</td>
        );
    } else if (weight === 0 || weight== "") {
        return (
            <td key={index}>-</td>
        );
    } else {
        return (
            <td key={index}>{weight}</td>
        );
    }
    
}

export default MeetsResults
