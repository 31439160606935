// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/Montserrat-SemiBold.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/Montserrat-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../fonts/Montserrat-Light.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "../fonts/Montserrat-Medium.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n    border: none;\n    margin: 0;\n    outline:none;\n    padding: 0;\n    text-decoration: none;\n}\n\n@font-face {\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-family: 'montserrat-semibold';\n}\n\n@font-face {\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-family: 'montserrat-regular';\n}\n\n@font-face {\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    font-family: 'montserrat-light';\n}\n\n@font-face {\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    font-family: 'montserrat-medium';\n}\n\n.red-cell {\n    background-color: #8B0000;\n}", "",{"version":3,"sources":["webpack://src/components/meets/table-entry.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,YAAY;IACZ,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,4CAA0C;IAC1C,kCAAkC;AACtC;;AAEA;IACI,4CAAyC;IACzC,iCAAiC;AACrC;;AAEA;IACI,4CAAuC;IACvC,+BAA+B;AACnC;;AAEA;IACI,4CAAwC;IACxC,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["* {\n    border: none;\n    margin: 0;\n    outline:none;\n    padding: 0;\n    text-decoration: none;\n}\n\n@font-face {\n    src: url(../fonts/Montserrat-SemiBold.ttf);\n    font-family: 'montserrat-semibold';\n}\n\n@font-face {\n    src: url(../fonts/Montserrat-Regular.ttf);\n    font-family: 'montserrat-regular';\n}\n\n@font-face {\n    src: url(../fonts/Montserrat-Light.ttf);\n    font-family: 'montserrat-light';\n}\n\n@font-face {\n    src: url(../fonts/Montserrat-Medium.ttf);\n    font-family: 'montserrat-medium';\n}\n\n.red-cell {\n    background-color: #8B0000;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
