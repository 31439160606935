import './registration.css';
import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import emailjs from "@emailjs/browser";

const Registration = ({language, meetId}) => {

    const questionMark = useRef();
    const noviceBox = useRef();
    const registrationContainer = useRef();
    const cardNumber = useRef();
    const cardContainerRef = useRef();
    const error1 = useRef();
    const error2 = useRef();
    const isFemale = useRef();
    const isMale = useRef();
    const isNew = useRef();
    const isNotNew = useRef();
    const noviceRegistration = useRef();
    const noviceContainer = useRef();
    const isNovice = useRef();
    const isNotNovice = useRef();
    const division = useRef();
    const division2 = useRef();
    const type = useRef();
    const type2 = useRef();
    const menFilter = useRef();
    const womenFilter = useRef();
    const menFilter2 = useRef();
    const womenFilter2 = useRef();

    const [isNoviceToRegister, setIsNoviceToRegister] = useState(false);
    const [card, setCard] = useState("");
    const [meetYear, setMeetYear] = useState(2022);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [bd, setBd] = useState("");
    const [gender, setGender] = useState("M");
    const [canClick, setCanClick] = useState(false);

    const [meet, setMeet] = useState({"limit": "0"});
    const [labels] = useState({"no-upcoming-meets": {"french": "Aucune compétition à afficher pour l'instant",
                                                        "english": "No competition to display for the moment"},
                                "upcoming-meets": {"french": "COMPÉTITIONS À VENIR",
                                                    "english": "UPCOMING COMPETITIONS"},
                                "by": {"french": "Année de naissance",
                                                    "english": "Birth year"},
                                "man": {"french": "Homme",
                                                    "english": "Man"},
                                "woman": {"french": "Femme",
                                                    "english": "Woman"},
                                "gender": {"french": "Genre",
                                                    "english": "Gender"},
                                "cl-eq": {"french": "Classique et équipé",
                                            "english": "Classic and equipped"},
                                "charge": {"french": "Responsable",
                                            "english": "Person in charge"},
                                "email": {"french": "Courriel",
                                            "english": "Email"},
                                "weighin": {"french": "Heure de la pesée",
                                            "english": "Weigh In Time"},
                                "weighin-time": {"french": "8h et 11h30",
                                            "english": "8am and 11:30am"},
                                "comp": {"french": "Heure de la compétition",
                                            "english": "Competition Time"},
                                "comp-time": {"french": "10h et 13h30",
                                            "english": "10am and 13:30pm"},
                                "deadline": {"french": "Date limite d'inscritpion",
                                            "english": "Registration deadline"},
                                "deadline-date": {"french": "Samedi 24 juillet",
                                            "english": "Saturday July 24"},
                                "limit": {"french": "Limite de participants",
                                            "english": "Limit of participants"},
                                "upcoming": {"french": "COMPÉTITIONS À VENIR",
                                            "english": "UPCOMING COMPETITIONS"},
                                "date": {"french": "21 AOÛT", "english": "AUG 21st"},
                                "fees": {"french": "Frais d'inscription", "english": "Registration fees"},
                                "fees-total": {"french": "$ (taxes et frais d'antidopage de 15$ inclus)",
                                        "english": "$ (taxes and anti-doping fees of $15 included)"},
                                "registration": {"french": "Inscription", "english": "Registration"},
                                "card": {"french": "Numéro de carte CPU", "english": "CPU card number"},
                                "continue": {"french": "CONTINUER", "english": "CONTINUE"},
                                "invalid": {"french": "NUMÉRO DE CARTE INVALIDE", "english": "INVALID CARD NUMBER"},
                                "invalid-des": {"french": "veuillez contacter l'administrateur du site internet à l'adresse nick3185@gmail.com si le numéro ne fonctionne pour plus d'un jour ouvrable",
                                                 "english": "please contact the website administrator at nick3185@gmail.com if the number does not work for more than one business day"},
                                "participants": {"french": "Liste des participants", "english": "List of participants"},
                                "identification": {"french": "Identification de l'athlète", "english": "Athlete identification"},
                                "first": {"french": "Prénom", "english": "First name"},
                                "last": {"french": "Nom", "english": "Last name"},
                                "wc": {"french": "Classe de poids", "english": "Weight class"},
                                "pl": {"french": "Dynamophilie", "english": "Powerlifting"},
                                "cl": {"french": "Classique", "english": "Classic"},
                                "eq": {"french": "Équipé", "english": "Equipped"},
                                "d1": {"french": "La FQD se réserve le droit de procéder à des tests de dépistage de drogue, selon la procédure et les normes utilisées par la CPU et la IPF, c’est-à-dire en prélevant des échantillons d’urine. En considération de mon inscription à cette compétition, je m’engage à renoncer à tout recours légal contre les organisateurs, le site de l’événement, les fédérations concernées ainsi que toute personne liée de près ou de loin à cette compétition.", 
                                        "english": "The QPF reserves the right to conduct drug testing, according to the procedure and standards used by the CPU and IPF, ie by taking urine samples. In consideration of my registration for this competition, I agree to waive any legal recourse against the organizers, the event site, the federations concerned as well as any person directly or indirectly linked to this competition."},
                                "d2": {"french": "Par la présente, je confirme avoir lu les règlements de l’IPF et pris connaissance de la liste de tout l’équipement approuvé.",
                                         "english": "I hereby confirm that I have read the IPF Rules and are aware of the list of all approved equipment."},
                                "d3": {"french": "J’ai lu et j’accepte les conditions",
                                         "english": "I have read and accept the conditions"},
                                "final": {"french": "Finaliser l'inscription",
                                         "english": "Finalize registration"},
                                "first-time": {"french": "Est-ce votre première compétition dans la FQD ?",
                                                "english": "Is this your first competition in the FQD?"},
                                "novice": {"french": "Voulez-vous vous inscrire comme novice ?",
                                                "english": "Do you want to register as a novice?"},
                                "fname": {"french": "Veuillez entrer votre prénom",
                                                "english": "Please enter your first name"},
                                "lname": {"french": "Veuillez entrer votre nom de famille",
                                                "english": "Please enter your last name"},
                                "year": {"french": "Veuillez entrer votre année de naissance",
                                                "english": "Please enter your year of birth"},
                                "email-er": {"french": "Veuillez entrer une adresse courriel valide",
                                                "english": "Please enter a valid email address"},
                                "p1": {"french": "Lors d’une première compétition, un nouveau leveur peut demander le statut \"Novice\". Ce statut permet d’essayer le sport de la dynamophilie sans être dans l’obligation d’avoir une carte de membre et tous les équipements obligatoires.",
                                                "english": "In a first competition, a new lifter can apply for \"Novice\" status. This status allows you to try out the sport of powerlifting without being required to have a membership card and all mandatory equipment."},
                                "p2": {"french": "Avantages d'être novice",
                                                "english": "Benefits of being a novice"},
                                "p3": {"french": "Désavantages d'être novice",
                                                "english": "Disadvantages of being a novice"},
                                "ad1": {"french": "La carte de membre n'est pas obligatoire.",
                                                "english": "The membership card is not compulsory."},
                                "ad2": {"french": "L'uniforme de leveur standard n'est pas obligatoire (singlet, bas de deadlift, etc). Par contre vous devez avoir une culotte courte et un t-shirt.",
                                                "english": "The standard lifting uniform is not mandatory (singlet, deadlift bottom, etc.). On the other hand, you must have short pants and a t-shirt."},
                                "ad3": {"french": "Vous n'êtes pas éligible au test anti-dopage et n'avez pas à payer le 15$.",
                                                "english": "You are not eligible for the anti-doping test and do not have to pay the $15."},
                                "dis1": {"french": "Aucun standard de qualification ne seront pris en compte pour le championnat provincial et canadien.",
                                                "english": "No qualification standard will be taken into account for the provincial and Canadian championships."},
                                "dis2": {"french": "Aucun record ne sera comptabilisé.",
                                                "english": "No record will be counted."}
                                                       
        });

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId, {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                setMeetYear(new Date(myJson[0].date).getFullYear());
                setMeet(myJson[0]);
            });
    // eslint-disable-next-line
    }, []);

    const handleChange = () => {
        let number = removeFirstCharIfNotNumber(cardNumber.current.value);
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/members/' + number, {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
            registrationContainer.current.className = "registration-container-on";
            error1.current.className = "hidden";
            error2.current.className = "hidden";
            setCard(cardNumber.current.value);
            setFirstName(myJson["first"]);
            setLastName(myJson["last"]);
            setBd(myJson["bd"]);
            if(myJson["gender"] === "F") {
                setGender("F");
                womenFilter.current.className = "filter visible";
                menFilter.current.className = "filter invisible";
            } else {
                setGender("M");
                menFilter.current.className = "filter visible";
                womenFilter.current.className = "filter invisible";
            }
          }).catch(function (e) {
            error1.current.className = "error-shown";
            error2.current.className = "error-shown";
        });
    };

    const handleIsNew = () => {
        if(isNotNew.current.className === "selected") {
            noviceContainer.current.className = "question";
            isNotNew.current.className = "";
            isNew.current.className = "selected";

            noviceRegistration.current.className = "hide";
            setIsNoviceToRegister(false);
        } else {
            noviceContainer.current.className = "hide question";
            isNotNew.current.className = "selected";
            isNew.current.className = "";

            isNotNovice.current.className = "selected";
            isNovice.current.className = "";
            cardContainerRef.current.className = "card-container";

            noviceRegistration.current.className = "hide";
            setIsNoviceToRegister(false);
        }
    };

    const handleIsNovice = () => {
        if(isNotNovice.current.className === "selected") {
            isNotNovice.current.className = "";
            isNovice.current.className = "selected";
            cardContainerRef.current.className = "hide";
            setIsNoviceToRegister(true);

            noviceRegistration.current.className = "novice-container";
        } else {
            isNotNovice.current.className = "selected";
            isNovice.current.className = "";
            cardContainerRef.current.className = "card-container";
            setIsNoviceToRegister(false);

            noviceRegistration.current.className = "hide";
        }
    };

    const handleMaleFemale = () => {
        if(isMale.current.className === "selected") {
            isFemale.current.className = "selected";
            isMale.current.className = "";

            womenFilter2.current.className = "filter";
            menFilter2.current.className = "filter invisible";
            setGender("F");
        } else {
            isFemale.current.className = "";
            isMale.current.className = "selected";

            womenFilter2.current.className = "filter invisible";
            menFilter2.current.className = "filter";
            setGender("M");
        };
    };

    const handleNoviceDetails = () => {
        if(questionMark.current.className === "explanation-off") {
            questionMark.current.className = "explanation-on";
            noviceBox.current.className = "novice-text";
        } else {
            questionMark.current.className = "explanation-off";
            noviceBox.current.className = "hide";
        }
    };

    const register = () => {
        if(isNoviceToRegister) {
            if(document.getElementById('lname').value === "") {
                alert(labels['lname'][language["language"]]);
            } else if(document.getElementById('fname').value === "") {
                alert(labels['fname'][language["language"]]);
            } else if(document.getElementById('year').value > 2020 || document.getElementById('year').value < 1940) {
                alert(labels['year'][language["language"]]);
            } else if(!document.getElementById('email').value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                alert(labels['email-er'][language["language"]]);
            } else {
                let age = meetYear - document.getElementById('year').value;
                let ac = "Open";
                if(age <= 9) {
                    ac = "M10";
                } else if(age <= 11) {
                    ac = "M12";
                } else if(age <= 13) {
                    ac = "M14";
                } else if(age <= 15) {
                    ac = "M16";
                } else if(age <= 18) {
                    ac = "Sub-Junior";
                } else if (age <= 23) {
                    ac = "Junior";
                } else if (age >= 70) {
                    ac = "Master IV";
                } else if (age >= 60) {
                    ac = "Master III";
                } else if (age >= 50) {
                    ac = "Master II";
                } else if (age >= 40) {
                    ac = "Master I";
                }

                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        number: 'novice',
                        last: document.getElementById('lname').value,
                        first: document.getElementById('fname').value,
                        ac: ac,
                        gender: gender,
                        type: type2.current.value,
                        division: division2.current.value,
                        wc: (gender === "M" ? menFilter2.current.value : womenFilter2.current.value),
                        email: document.getElementById('email').value,
                        status: ""
                    })
                };
                fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId + '/participants', requestOptions);
                var start = new Date().getTime();
                var end=0;
                while( (end-start) < 1500){
                    end = new Date().getTime();
                };
                window.location.href = "/meets/" + meetId + "/confirmation/";
            }
        } else {
            let age = meetYear - new Date(bd).getFullYear();
            let ac = "Open";
            if(age <= 9) {
                ac = "M10";
            } else if(age <= 11) {
                ac = "M12";
            } else if(age <= 13) {
                ac = "M14";
            } else if(age <= 15) {
                ac = "M16";
            } else if(age <= 18) {
                ac = "Sub-Junior";
            } else if (age <= 23) {
                ac = "Junior";
            } else if (age >= 70) {
                ac = "Master IV";
            } else if (age >= 60) {
                ac = "Master III";
            } else if (age >= 50) {
                ac = "Master II";
            } else if (age >= 40) {
                ac = "Master I";
            }

            let number = removeFirstCharIfNotNumber(cardNumber.current.value);
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    number: number,
                    last: lastName,
                    first: firstName,
                    ac: ac,
                    gender: gender,
                    type: type.current.value,
                    division: division.current.value,
                    wc: (gender === "M" ? menFilter.current.value : womenFilter.current.value),
                    status: ""
                })
            };
            fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId + '/participants', requestOptions);
        }
    };

    function removeFirstCharIfNotNumber(str) {
        if (isNaN(str.charAt(0))) {
            return str.substring(1);
        }
        return str;
    }

    return (
        <div className="meet-registration-container">
            <h2>{labels['registration'][language["language"]]}</h2>
            <h1>{meet.nom}</h1>

            <div className="info-container">
                <div className="details">
                    <div className="detail">
                        <p className="detail-label">Division</p>
                        <p className="detail-text">{labels['cl-eq'][language["language"]]}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['charge'][language["language"]]}</p>
                        <p className="detail-text">{meet.charge}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['email'][language["language"]]}</p>
                        <p className="detail-text email">{meet.email}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['weighin'][language["language"]]}</p>
                        <p className="detail-text">{meet.weighin}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['comp'][language["language"]]}</p>
                        <p className="detail-text">{meet.comp}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['fees'][language["language"]]}</p>
                        <p className="detail-text">{meet.cost + labels['fees-total'][language["language"]]}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['deadline'][language["language"]]}</p>
                        <p className="detail-text">{meet.limit.substring(0, 10)}</p>
                    </div>
                    <div className="detail">
                        <p className="detail-label">{labels['limit'][language["language"]]}</p>
                        <p className="detail-text">{meet.number}</p>
                    </div>
                </div>
            </div>

            <div className="line"></div>

            <div className="first-time card-container">
                <div className="question">
                    <label>{labels['first-time'][language["language"]]}</label>
                    <div className="btn-group">
                        <button onClick={() => handleIsNew()} ref={isNew}>Oui</button>
                        <button onClick={() => handleIsNew()} ref={isNotNew} className="selected">Non</button>
                    </div>
                </div>

                <div  ref={noviceContainer} className="hide question">
                    <label>{labels['novice'][language["language"]]}</label>
                    <div className="btn-group">
                        <button onClick={() => handleIsNovice()} ref={isNovice}>Oui</button>
                        <button onClick={() => handleIsNovice()} ref={isNotNovice} className="selected">Non</button>
                        <button ref={questionMark} onClick={() => handleNoviceDetails()} className="explanation-off">?</button>
                    </div>
                </div>
                <div ref={noviceBox} className="hide">
                    <p>{labels['p1'][language["language"]]}</p>

                    <p className="bold">{labels['p2'][language["language"]]}</p>
                    <ul>
                        <li>{labels['ad1'][language["language"]]}</li>
                        <li>{labels['ad2'][language["language"]]}</li>
                        <li>{labels['ad3'][language["language"]]}</li>
                    </ul>

                    <p className="bold">{labels['p3'][language["language"]]}</p>
                    <ul>
                        <li>{labels['dis1'][language["language"]]}</li>
                        <li>{labels['dis2'][language["language"]]}</li>
                    </ul>
                </div>
            </div>

            <div className="line"></div>

            <div ref={cardContainerRef} className="hide card-container">
                <label>{labels['card'][language["language"]]}<input type="text" ref={cardNumber}/></label>
                <p ref={error1} className="hidden">-- {labels['invalid'][language["language"]]} -- </p>
                <p ref={error2} className="hidden">-- {labels['invalid-des'][language["language"]]} -- </p>
                <button onClick={() => handleChange(cardNumber)}>{labels['continue'][language["language"]]}</button>
            </div>

            <div ref={noviceRegistration} className="hide"> 
                <div className="novice-info-container details-container">
                    <div className="details">
                        <p className="detail-label">{labels['first'][language["language"]]}</p>
                        <input type="text" id="fname" name="fname" />
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['last'][language["language"]]}</p>
                        <input type="text" id="lname" name="lname" />
                    </div>
                    <div className="details sex-box">
                        <p className="detail-label">{labels['gender'][language["language"]]}</p>
                        <div className="sex-toggle">
                            <button onClick={() => handleMaleFemale()} className="selected" ref={isMale}>{labels['man'][language["language"]]}</button>
                            <button onClick={() => handleMaleFemale()} ref={isFemale}>{labels['woman'][language["language"]]}</button>
                        </div>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['by'][language["language"]]}</p>
                        <input type="number" id="year"/>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['email'][language["language"]]}</p>
                        <input type="email" id="email" name="email" />
                    </div>
                </div>

                <div className="line"></div>

                <div className="novice-athlete-container details-container">
                    <div className="details">
                        <p className="detail-label">Type</p>
                        <select ref={type2} className="filter">
                            <option value="Dynamophilie">{labels['pl'][language["language"]]}</option>
                            <option value="Bench Press">Bench Press</option>
                        </select>
                    </div>

                    <div className="details">
                        <p className="detail-label">Division</p>
                        <select ref={division2} className="filter">
                            <option value="Classique">{labels['cl'][language["language"]]}</option>
                            <option value="Équipé">{labels['eq'][language["language"]]}</option>
                        </select>
                    </div>

                    <div className="details">
                        <p className="wc-novice">{labels['wc'][language["language"]]}</p>
                        <div className="select-container">
                            <select ref={menFilter2} className="filter">
                                <option value="-53 kg">-53 kg</option>
                                <option value="-59 kg">-59 kg</option>
                                <option value="-66 kg">-66 kg</option>
                                <option value="-74 kg">-74 kg</option>
                                <option value="-83 kg">-83 kg</option>
                                <option value="-93 kg">-93 kg</option>
                                <option value="-105 kg">-105 kg</option>
                                <option value="-120 kg">-120 kg</option>
                                <option value="+120 kg">+120 kg</option>
                            </select>
                            <select ref={womenFilter2} className="filter invisible">
                                <option value="-43 kg">-43 kg</option>
                                <option value="-47 kg">-47 kg</option>
                                <option value="-52 kg">-52 kg</option>
                                <option value="-57 kg">-57 kg</option>
                                <option value="-63 kg">-63 kg</option>
                                <option value="-69 kg">-69 kg</option>
                                <option value="-76 kg">-76 kg</option>
                                <option value="-84 kg">-84 kg</option>
                                <option value="+84 kg">+84 kg</option>
                            </select>
                        </div>
                    </div>

                    <Link to={"/meets/" + meetId + "/registration/"} onClick={() => register()} className="final">{labels['final'][language["language"]]}</Link>
                </div>
            </div>

            <div ref={registrationContainer} className="registration-container-off">
                <div className="details-section">
                    <div className="line"></div>
                    <p>{labels['identification'][language["language"]]}</p>
                    <div className="line"></div>
                </div>

                <div className="details-container">
                    <div className="details">
                        <p className="detail-label">{labels['card'][language["language"]]}</p>
                        <p className="detail-text">{card}</p>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['first'][language["language"]]}</p>
                        <p className="detail-text">{firstName}</p>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['last'][language["language"]]}</p>
                        <p className="detail-text">{lastName}</p>
                    </div>
                </div>

                <div className="details-section">
                    <div className="line"></div>
                    <p>{labels['registration'][language["language"]]}</p>
                    <div className="line"></div>
                </div>

                <div className="details-container">
                    <div className="details">
                        <p className="detail-label">Type</p>
                        <div className="select-container">
                            <select ref={type} className="filter">
                                <option value="Dynamophilie">{labels['pl'][language["language"]]}</option>
                                <option value="Bench Press">Bench Press</option>
                            </select>
                        </div>
                    </div>
                    <div className="details">
                        <p className="detail-label">Division</p>
                        <div className="select-container">
                            <select ref={division} className="filter">
                                <option value="Classique">{labels['cl'][language["language"]]}</option>
                                <option value="Équipé">{labels['eq'][language["language"]]}</option>
                            </select>
                        </div>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['wc'][language["language"]]}</p>
                        <div className="select-container">
                            <select ref={menFilter} className="filter invisible">
                                <option value="-53 kg">-53 kg</option>
                                <option value="-59 kg">-59 kg</option>
                                <option value="-66 kg">-66 kg</option>
                                <option value="-74 kg">-74 kg</option>
                                <option value="-83 kg">-83 kg</option>
                                <option value="-93 kg">-93 kg</option>
                                <option value="-105 kg">-105 kg</option>
                                <option value="-120 kg">-120 kg</option>
                                <option value="+120 kg">+120 kg</option>
                            </select>
                            <select ref={womenFilter} className="filter invisible">
                                <option value="-43 kg">-43 kg</option>
                                <option value="-47 kg">-47 kg</option>
                                <option value="-52 kg">-52 kg</option>
                                <option value="-57 kg">-57 kg</option>
                                <option value="-63 kg">-63 kg</option>
                                <option value="-69 kg">-69 kg</option>
                                <option value="-76 kg">-76 kg</option>
                                <option value="-84 kg">-84 kg</option>
                                <option value="+84 kg">+84 kg</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="disclaimers-container">
                    <p className="disclaimers">{labels['d1'][language["language"]]}</p>
                    <p className="disclaimers">{labels['d2'][language["language"]]}</p>
                    <p className="disclaimers">{labels['d3'][language["language"]]} &emsp; <input type="checkbox" onClick={() => setCanClick(!canClick)}></input></p>
                </div>

                <Link to={"/meets/" + meetId + "/confirmation/"} onClick={() => register()} className={canClick ? "final" : "display-none"}>{labels['final'][language["language"]]}</Link>
            </div>
        </div>
    );
}

export default Registration
